import type { Article } from 'interfaces/content/articles/Articles';
import { SubscriberContext } from 'services/Subscriber';
import { EventData } from 'services/Gtm/entities';
import { trackEvent } from 'services/Gtm/functions';
import { PageType } from 'interfaces/content/articles/Post';
import CustomWindow from 'interfaces/Utils';
import CookieService from './miscUtils/CookieService';

declare let window: CustomWindow;

export const getArticleTrackingData = (article: Article, user: SubscriberContext, subdomain: string) => {
  const hasFeaturedMediaTypeVideo =
    article.featuredMediaType === 'fortune_video' || article.featuredMediaType === 'stn_video_media';

  const trackingData: EventData = {
    HasVideoCD: hasFeaturedMediaTypeVideo,
    articleNameCD: article.title,
    authorNameCD: article.authorNames,
    cmsCategoryCD: 'article',
    cmsSubCategoryCD: article.primarySection.name,
    contentCMSID: article.postId,
    contentPlacementCD: `${subdomain} Widget`,
    contentPublishedDateCD: article.dateGmt,
    contentTagsCD: article.tagNames,
    hitIDCD: '',
    loginStatusCD: user.isLoggedIn ? 'logged-in' : 'guest',
    platformCD: article.platformCD || 'own',
    premiumCategoryCD: article?.premiumCategory.name || '',
    sessionIDCD: '',
    userAgentCD: typeof window !== 'undefined' ? window.navigator?.userAgent : '',
  };

  return { trackingData };
};

export const trackInlineButtonClick = (event: Event, defaultTrackingData: EventData, subdomain: string) => {
  const trackingData = {
    ...defaultTrackingData,
    contentPlacementCD: `Inline ${subdomain} Button`,
    eventAction: 'inline click',
    eventCategory: `Inline ${subdomain} Button`,
    eventLabel: (event.target as HTMLAnchorElement).textContent || '',
  };

  trackEvent(trackingData);
};

export const trackArticleLinkClick = (event: Event, defaultTrackingData: EventData) => {
  const target: HTMLAnchorElement = event.target as HTMLAnchorElement;
  // the anchor element that was clicked could be a wrapper of another element (e.g. image)
  // in this case the target might not be the anchor itself
  const articleUrl = target.tagName === 'A' ? target.href : target?.closest('a')?.href;
  if (!articleUrl) return;
  let urlDomain;
  try {
    urlDomain = new URL(articleUrl).origin;
  } catch (err) {
    return;
  }
  const isExternalURL = urlDomain !== window.location.origin;
  if (!isExternalURL) return;

  const trackingData = {
    ...defaultTrackingData,
    contentPlacementCD: 'Article Body',
    eventAction: urlDomain,
    eventCategory: 'Outbound Link Click',
    eventLabel: articleUrl,
  };

  trackEvent(trackingData);
};

export const setEventAction = (eyebrowSectionLabel: string, eyebrowTagLabel: string): string => {
  let eventActionContent;

  if (
    eyebrowSectionLabel !== 'No Primary Section Found' &&
    eyebrowTagLabel !== 'No Primary Tag Found' &&
    eyebrowTagLabel !== ''
  ) {
    eventActionContent = `${eyebrowSectionLabel} : ${eyebrowTagLabel}`;
  } else if (eyebrowSectionLabel !== 'No Primary Section Found') {
    eventActionContent = `${eyebrowSectionLabel}`;
  } else {
    eventActionContent = 'Not Set';
  }

  return eventActionContent;
};

export const setPrefixedDbID = (ID: string | number, pageType?: PageType): string => {
  switch (pageType) {
    case PageType.EDU_CATEGORY:
      return `C${ID}`;
    case PageType.EDU_RANKING_LIST:
      return `R${ID}`;
    case PageType.EDU_SCHOOL:
      return `S${ID}`;
    case PageType.FRANCHISE_LIST:
      return `fl_${ID}`;
    case PageType.COMPANY:
      return `co_${ID}`;
    default:
      return `${ID}`;
  }
};

export const getSessionIdentifiers = () => {
  try {
    const { tp } = window;
    const user = tp?.pianoId?.getUser();
    const sessionIdentifiers = {
      fuid: CookieService.get('fuid') || '',
      ga_client_id: CookieService.get('_ga')?.slice(6) || '',
      piano_user_id: user?.uid || '',
    };

    return sessionIdentifiers;
  } catch {
    return {
      fuid: '',
      ga_client_id: '',
      piano_user_id: '',
    };
  }
};

export interface SegmentPageviewDataProps {
  authors?: string;
  dateGmt: string | null;
  hasStnVideo: boolean;
  primarySection: string;
  pageType: PageType;
  prefixedDatabaseId?: string | null;
  databaseId: string | number;
  subDomain?: string;
  premiumCategory?: string;
  tagNames: string[];
}

interface VisitorIDCookie {
  browserId: string;
}

export const segmentPageviewProps = (
  pageProps: SegmentPageviewDataProps,
  referrer: string,
  queryParams: string,
  isLoggedIn: boolean | null,
): string => {
  try {
    const {
      authors,
      dateGmt,
      hasStnVideo,
      primarySection,
      pageType,
      prefixedDatabaseId,
      databaseId,
      subDomain,
      premiumCategory,
      tagNames,
    } = pageProps;

    const fuidCookie = CookieService.get('fuid');
    const pianoUserIdCookie = CookieService.get('pianoUid');
    const pianoVisitorIdCookie = CookieService.get('_pcid');
    const parsedPianoVisitorId =
      pianoVisitorIdCookie && (JSON.parse(pianoVisitorIdCookie) as VisitorIDCookie)?.browserId;
    const gtmReferrerCookie = CookieService.get('__gtm_referrer');
    const campaignUrlCookie = CookieService.get('__gtm_campaign_url');
    const gacidCookie = CookieService.get('_ga')?.slice(6);

    const payload = {
      ...{ logged_in: isLoggedIn ?? false },
      ...(authors && { content_authors: authors }),
      ...(dateGmt && { content_publication_date: dateGmt }),
      ...(primarySection && { content_section: primarySection }),
      ...(referrer && { document_referrer: referrer }),
      ...(fuidCookie && fuidCookie !== 'undefined' && { fuid: fuidCookie }),
      ...{ has_stn_video: hasStnVideo },
      ...(gacidCookie && { ga_client_id: gacidCookie }),
      ...(gtmReferrerCookie && { gtm_referrer: gtmReferrerCookie }),
      ...(pageType && { page_content_type: pageType }),
      ...(subDomain && { page_platform: subDomain }),
      ...(pianoUserIdCookie && { piano_user_id: pianoUserIdCookie }),
      ...(parsedPianoVisitorId && { piano_visitor_id: parsedPianoVisitorId }),
      ...((prefixedDatabaseId || databaseId) && { post_id: prefixedDatabaseId || databaseId }),
      ...(premiumCategory && { premium_category: premiumCategory }),
      ...((queryParams || campaignUrlCookie) && { query_string: queryParams || campaignUrlCookie }),
      ...(tagNames && { tags_array: `${tagNames}` }),
    };

    return JSON.stringify(payload);
  } catch {
    return '{}';
  }
};
