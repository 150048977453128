import styled, { css } from 'styled-components';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import { arrowDown } from 'styles/icons';

export const Wrapper = styled.div`
  position: relative;
`;

export const Label = styled.label<{ theme: Theme }>`
  display: block;
  margin-bottom: 4px;
  ${getBodyStyle('graphikCond', { default: 'default' })};

  span {
    color: ${getColor('textAccentSecondary')};

    &.disclaimer {
      color: ${getColor('textPrimary')}
    }
  }

  &.error {
    color: ${getColor('textAccentSecondary')};
  }

  &.disabled {
    color: ${getColor('textSecondary')};
  }
`;

export const Button = styled.button<{ $size: 'large' | 'small'; theme: Theme }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  width: 100%;
  background: ${getColor('surfaceWhite')};
  padding: 8px 0 8px 16px;
  border: 1px solid ${getColor('borderTertiary')};
  ${getBodyStyle('graphikCond', { default: 'default' })};
  
  &[aria-expanded="true"] {
    font-weight: 600;
  }

  &.has-placeholder {
    color: ${getColor('textSecondary')};
    font-weight: normal;
  }

  &::after {
    ${arrowDown};
    border-width: 0 2px 2px 0;
    padding: 4.5px;
    border-color: ${getColor('borderBlack')};
    position: absolute;
    margin-bottom: 5px;
    right: 16px;
  }
  span {
    padding-right: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:disabled {
    border: 1px solid ${getColor('neutralLightGray3')};
    cursor: default;

    &::before {
      border-color: ${getColor('neutralLightGray3')};
    }
  }

  &[aria-expanded="true"] {
    border: 2px solid ${getColor('borderBlack')};
    border-bottom: none;

    &:hover, &:focus-visible, &:focus  {
      border-bottom: none;
    }
  }

  &.error:not([aria-expanded="true"]) {
    border-color: ${getColor('borderAccentSecondary')};
  }

  &[aria-expanded="false"]:not(:disabled) {
    &:hover {
      border: 1px solid ${getColor('borderAccentPrimary')};
    }
  }

  &:focus-visible:not([aria-expanded="true"]), &:focus:not([aria-expanded="true"]) {
    box-shadow: 0 0 5px 0 ${getColor('textLink')};
    outline: none;
  }

  ${({ $size }) => {
    switch ($size) {
      case 'large':
        return css`
          height: 56px;
          padding: 16px;
        `;
      case 'small':
        return css`
          height: 40px;
          padding: 8px 16px;
        `;
      default:
        return null;
    }
  }} 
`;

export const List = styled.ul<{ theme: Theme }>`
  background: ${getColor('surfaceWhite')};
  position: absolute;
  left: 0;
  right: 0;
  max-height: 250px;
  overflow-y: auto;
  opacity: 0;
  transform: scale(1,0);
  transform-origin: top left;
  pointer-events: none;
  z-index: 2;
  border: 0 2px 2px 2px solid ${getColor('borderBlack')};

  &.active {
    opacity: 1;
    transform: scale(1,1);
    pointer-events: auto;
    border-width: 0 2px 2px 2px;
    border-color: ${getColor('borderBlack')};
    border-style: solid;
    margin: 0;
    z-index: 6; // other form elements ex range input controllers have index 5 and appear over dropdown
  }
`;

export const ListItem = styled.li<{ theme: Theme; $isOption: boolean; $isSelected?: boolean }>`
  padding: 8px 8px 8px 16px;
  ${getBodyStyle('graphikCond', { default: 'default' })};

  ${({ $isOption }) =>
    $isOption &&
    css<{ theme: Theme }>`
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      background: ${getColor('shadeBrandPrimary100')};
    }
  `}

  ${({ $isSelected }) =>
    ($isSelected ?
      css<{ theme: Theme }>`
        background: ${getColor('shadeBrandPrimary200')};
      ` :
      css<{ theme: Theme }>`
        background: ${getColor('surfaceWhite')};
      `)}
`;

export const Text = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' })}
  color: ${getColor('textPrimary')};
  line-height: 18px;
  margin-top: 4px;

  &.disabled {
    color: ${getColor('textSecondary')};
  }

  &.error {
    color: ${getColor('textAccentSecondary')};
  }
`;

export const SearchInput = styled.input`
  border: none;
  width: 100%;
  position: absolute;
  background: transparent;
  height: 100%;
  left: 0;
  outline: none;
  padding: 10px;
`;
