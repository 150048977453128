import { useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import useBreakpoint from 'hooks/useBreakpoint';
import { StyledVideo, CloseButton, Container, CloseIconContainer, Wrapper } from './VideoEmbedStyles';

export interface VideoEmbedProps {
  src: string;
  width: string;
  height: string;
  disableAutoplay?: boolean;
}

const VideoEmbed = ({ src, width, height, disableAutoplay = false }: VideoEmbedProps) => {
  const isLgMin = useBreakpoint('lgMin');
  const [play, setPlay] = useState(false);
  const [dockingMode, setDockingMode] = useState(false);
  const [disableDockingMode, setDisableDockingMode] = useState(false);
  const [videoContainerRef, inViewPort] = useInView({ threshold: 0.5 });

  useEffect(() => {
    if (!play && !disableDockingMode) {
      const timer = setTimeout(() => {
        if (inViewPort) {
          setPlay(!disableAutoplay);
        }
      }, 1000);
      return () => clearTimeout(timer);
    }
    if (play && !inViewPort && isLgMin) {
      setDockingMode(true);
    }

    if (play && !inViewPort && !isLgMin) {
      setDockingMode(false);
      setPlay(false);
    }

    if (play && inViewPort) {
      setDockingMode(false);
    }
    return () => {};
  }, [disableAutoplay, disableDockingMode, inViewPort, isLgMin, play]);

  return (
    <Wrapper ref={videoContainerRef}>
      <Container $dockingMode={dockingMode}>
        <CloseButton
          $dockingMode={dockingMode}
          type='button'
          onClick={() => {
            setDockingMode(false);
            setDisableDockingMode(true);
            setPlay(false);
          }}
        >
          <CloseIconContainer />
        </CloseButton>
        <StyledVideo
          dockingMode={dockingMode}
          height={height}
          width={width}
          src={src}
          controls
          autoPlay
          muted
        />
      </Container>
    </Wrapper>
  );
};

export default VideoEmbed;
