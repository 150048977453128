export const HEADER_NAVIGATION = {
  BURGER_MENU: 'burger-button',
  FORTUNE_LOGO: 'fortune-logo',
  HEADER_NAV_BAR: 'header',
  LOGOUT_BUTTON: 'logoutBtnDesktop',
  MY_ACCOUNT_DROPDOWN: 'myAccountDropdown',
  SEARCH_BUTTON: 'searchButton',
  SEARCH_CONTAINER: 'div[class*=queryly_search_header]',
  SEARCH_NO_RESULT: 'div[class*=queryly_noresultmessage]',
  SEARCH_RESULTS_CONTAINER: 'div[id*=queryly_resultscontainer]',
  SEARCH_RESULTS_ITEM: 'div[class*=queryly_item_container]',
  SIGNIN_BUTTON: 'signinBtnDesktop',
  SUBSCRIBE_NOW_BUTTON: 'subscribeBtnDesktop',
};

export default HEADER_NAVIGATION;
