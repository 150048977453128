import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const NavigationContent = styled.header<{ theme: Theme }>`
  margin: 0 auto;
  height: 56px;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  padding: 0;
  font-family: ${font.graphikCond};
  font-weight: 600;
  border-bottom: 1px solid ${getColor('borderPrimary')};

  @media (${breakpoint.mdMin}) {
    padding: 0 24px;
  }

  @media (${breakpoint.lgMin}) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (${breakpoint.xlMin}) {
    max-width: 1345px;
    padding: 0 48px;
  }
  @media (${breakpoint.xxlMin}) {
    padding: unset;
  }

  &.own, &.europe, &.asia, &.education, &.recommends {
    grid-template-columns: 1fr 140px 1fr;
  }
  
  &.crypto, &.well {
    grid-template-columns: 1fr 307px 1fr;
  }
`;

export const NavigationLeft = styled.div<{ theme: Theme }>`
  display: none;

  @media (${breakpoint.mdMin}) {
    display: flex;
  }
`;

export const NavigationSearchWrapper = styled.div<{ theme: Theme }>`
  display: none;
  align-items: center;
  cursor: pointer;

  > label {
    color: ${getColor('textPrimary')};
    font-size: 20px;
    cursor: pointer;
    margin: auto;

    &:hover {
      color: ${getColor('textSecondary')};
    }
  }
  
  @media (${breakpoint.mdMin}) {
    display: flex;
  }
`;

export const NavigationCenter = styled.div<{ theme: Theme }>`
  text-align: center;
  display: none;
  
  svg {
    height: 32px;
  }
  
  .recommends-logo {
    width: 380px;
  }

  @media (${breakpoint.mdMin}) {
    display: flex;
  }
`;

export const NavigationRight = styled.div<{ theme: Theme }>`
  display: none;
  justify-self: end;
  position: relative;
  align-items: center;
  min-height: 24px;

  @media (${breakpoint.mdMin}) {
    display: flex;
  }

  .account-placeholder {
    position: absolute;
    margin: auto;
    top: 0; bottom: 0; right: 0;
  }

  .subscribe-link {
    text-transform: uppercase;
    max-height: 38px;

    @media (${breakpoint.mdMin}) {
      padding: 8px 16px;
      margin-left: 24px;
    }
    
    @media (${breakpoint.lgMin}) {
      margin-left: 40px;
      font-size: 16px;
    }
  }

  .sign-in-button {
    margin: 0;
    margin-left: 16px;
    text-decoration: none;
    color: ${getColor('neutralBlack')};

    &:hover {
      text-decoration: none;
      color: ${getColor('textSecondary')};
    }

    &:active {
      color: ${getColor('neutralDarkGray')};
    }

    @media (${breakpoint.mdMin}) {
      margin-right: 16px;
    }
      
    @media (${breakpoint.lgMin}) {
      font-size: 20px;
      margin-left: 24px;
      margin-right: 0;
    }
  }
`;

export const NavigationPlaceholder = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 8px;
  height: 54px;

  .menu-placeholder {
    margin-right: 24px;
  }

  @media (${breakpoint.mdMin}) {
    padding: 0px 24px;
    height: 68px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 380px 1fr;
  }

  @media (${breakpoint.xlMin}) {
    padding: 0px 48px;
    margin: 0 auto;
  }

   @media (${breakpoint.xxlMin}) {
    max-width: 1440px;
  }
`;
