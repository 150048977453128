import { MenuItemProps } from 'interfaces/navigation/Header';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { COMMON_TEST_IDS } from 'constants/testsIds/common';
import * as S from './NavigationStyles';
import NavItem from './NavItem';

export interface TopicNavigationProps {
  color: string;
  topics: MenuItemProps[];
  subdomain: string;
  className?: string;
}

const TopicNavigation = ({ color, topics, subdomain, className }: TopicNavigationProps) => {
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState('');

  useEffect(() => {
    setCurrentUrl(router.asPath.split('?')[0]);
  }, [router.asPath]);

  if (topics.length === 0) return null;

  return (
    <S.NavigationWrapper className={className}>
      <S.NavigationList data-cy={COMMON_TEST_IDS.TOPIC_NAVIGATION}>
        {topics.map((item) => (
          <NavItem
            key={item.id}
            {...item}
            color={color}
            subdomain={subdomain}
            currentUrl={currentUrl}
          />
        ))}
      </S.NavigationList>
    </S.NavigationWrapper>
  );
};

export default TopicNavigation;
