const EDUCATION_RANKINGS_TEST_IDS = {
  FOOTER_DISCLAIMER: 'footer-disclaimer',
  FOOTER_NAVIGATION: 'footer-navigation',
  FOOTER_NAVIGATION_LINK: 'footer-navigation-link',
  FOOTER_NAVIGATION_LINK_WRAPPER: 'footer-navigation-link-wrapper',
  FOOTER_NAVIGATION_SECTION_TITLE: 'footer-navigation-section-title',
  PRODUCT_CARD: 'product-card-',
  PRODUCT_CARD_BOTTOM: 'product-card-bottom',
  PRODUCT_CARD_CONTENT: 'product-card-content',
  PRODUCT_CARD_IMAGE: 'product-card-image',
  RANKING_LIST: 'ranking-list-edu',
  SCROLL_UP_BUTTON: 'scroll-to-top',
  SOCIAL_LIST: 'social-list',
};

export default EDUCATION_RANKINGS_TEST_IDS;
