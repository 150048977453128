import styled, { css } from 'styled-components';
import { font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import { getIcon } from 'styles/icons';

const arrowSize = 20;

const level0 = css`
  font-weight: 600;
  font-size: 20px;
`;

const level1 = css`
  font-weight: 500;
  font-size: 20px;
`;

const level2 = css`
  font-weight: 400;
  font-size: 16px;
`;

export const Item = styled.li<{ $nestingLevel: number; $subMenu: boolean; theme: Theme }>`
  display: inline-flex;
  align-items: center;
  position: relative;
  font-family: ${font.graphikCond};
  color: ${getColor('textPrimary')};
  justify-content: space-between;
  line-height: 120%;

  ${(props) => {
    switch (props.$nestingLevel) {
      case 0:
        return level0;
      case 1:
        return level1;
      default:
        return level2;
    }
  }}
  
  a {
    flex: 1;
    color:  ${getColor('textPrimary')};
 
    text-transform: uppercase;
  }

  > a:only-child {
    margin-right: ${arrowSize}px;
  }
`;

export const Submenu = styled.ul`
`;

export const Icon = styled.button<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 8px;
  margin: -8px;

  .arrow {
    ${getIcon('next-arrow')}
    border-color: ${getColor('iconPrimary')};
    padding: 5px;
    border-width: 0 3px 3px 0;
    margin-top: 0;
  }

  &:hover  .arrow {
    border-color: ${getColor('textSecondary')};
  }

  &:focus  .arrow {
    border-color: ${getColor('iconSecondary')};
  }

  &.right {
    transform: rotate(-180deg);
  }
`;
