/* eslint-disable max-len */
import Script from 'next/script';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { ArcSpanPageConfig } from './entities';

export * from './setarcSpanPageConfig';

interface ArcSpanProps {
  arcSpanPageConfig: ArcSpanPageConfig;
  pageAdConfig: PageAdConfig | undefined;
}

const ArcSpan = ({ arcSpanPageConfig, pageAdConfig }: ArcSpanProps) => {
  const arcSpanConfig = {
    ...pageAdConfig,
    ...arcSpanPageConfig,
  };

  return (
    <Script
      id='arcSpan'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
                (function() {
                  window.arcSpanPageConfig = ${JSON.stringify(arcSpanConfig)};
                  var s = document.createElement('script'), el = document.getElementsByTagName('script')[0];
                  s.async = true;
                  var _a = (new URL(location.href));
                  var _p = (_a.origin.replace('staging.', 'www.')) + _a.pathname.replace(/\\/$/, '');
                  s.type = 'text/javascript';
                  s.src = 'https://${process.env.ARCSPAN_ORGANIZATION_ID}.p7cloud.net/as1.js?uri=' + _p;
                  el.parentNode.insertBefore(s, el);
                })();
              `,
      }}
    />
  );
};

export default ArcSpan;
