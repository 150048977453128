/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { trackEvent } from 'services/Gtm/functions';
import { EventData } from 'services/Gtm/entities';
import { ButtonSizeStyling } from 'components/Globals/Base/Button/types';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import cx from 'classnames';
import CustomWindow from 'interfaces/Utils';
import { LinkTypes } from './types';
import * as S from './LinkStyles';

declare let window: CustomWindow;

export interface CustomLinkProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  dataCy?: string;
  trackingData?: EventData;
  // TODO: the ariaLabel prop can be removed and sent directly through aria-label
  ariaLabel?: string;
  type?: keyof typeof LinkTypes;
  size?: { default: keyof ButtonSizeStyling; [key: string]: keyof ButtonSizeStyling };
  center?: boolean;
  hasRightArrow?: boolean;
  hasLeftArrow?: boolean;
  onKeyDown?: () => void;
  onMouseEnter?: () => void;
}

// make full reloads when changing a route, in order to disable SPA
export const changeRoute = (url: string) => {
  window.location.href = url;
  return Promise.resolve(true);
};

export const parseHref = (href?: string) => {
  const whitelistedEnvironments = ['dev', 'qa', 'staging', 'feature'];
  const currentEnvironment = process.env.ENVIRONMENT;

  // if the app is in development mode or if it's built for the remote dev and qa envs, preserve the href
  if (
    process.env.NODE_ENV === 'development' ||
    (currentEnvironment && whitelistedEnvironments.includes(currentEnvironment))
  ) {
    return href;
  }

  if (!href) {
    return '';
  }

  if (href.startsWith('/')) {
    return `https://fortune.com${href}`;
  }

  return href;
};

/**
 * Wrapper component around next/link that allows passing a className (which is often needed on links).
 * This component also handles tracking on clicks.
 */
const CustomLink = ({
  href,
  target = '_self',
  ariaLabel = '',
  children,
  className = '',
  dataCy,
  trackingData,
  type,
  size,
  center,
  hasRightArrow = true,
  hasLeftArrow = false,
  ...rest
}: CustomLinkProps) => {
  const parsedHref = parseHref(href);

  const handleClick = () => {
    if (trackEvent && trackingData) trackEvent(trackingData);
  };

  return (
    <S.Link
      $type={type || 'primary'}
      $size={size}
      $center={center}
      $hasRightArrow={hasRightArrow}
      $hasLeftArrow={hasLeftArrow}
      href={parsedHref}
      target={target}
      className={cx(className, { 'styled-custom-link': type })}
      data-cy={dataCy}
      onClick={handleClick}
      {...rest}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      suppressHydrationWarning
    >
      {children}
    </S.Link>
  );
};

export default CustomLink;
