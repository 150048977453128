import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import { PageType } from 'interfaces/content/articles/Post';

export const RightRailMultipleContainer = styled.div<{ $marginTop: number }>`
    margin-top: ${(props) => props.$marginTop}px;
    display: flex;
    flex-direction: column;
    & > div {
        flex: 1;
    }
`;

export const RightRailArticlesStickyContainer = styled.div`
    display: none;
    min-width: 300px;
    position: relative;
    height: 100%;
    width: 300px;

    @media (${breakpoint.lgMin}) {
        display: block;
    }

    @media (${breakpoint.xlMin}) {
        display: block;
        margin-left: auto;
        margin-right: 0px;
    }

    @media (${breakpoint.xxlMin}) {
        display: block;
        margin-left: 0px;
    }
`;

interface RightRailArticlesStickyAdProps {
  $stickyTop?: number | null;
}

export const RightRailArticlesStickyAd = styled.div<RightRailArticlesStickyAdProps>`
    min-height: 250px;
    position: sticky;
    width: 300px;
    top: ${({ $stickyTop }) => ($stickyTop ? $stickyTop + 10 : 100)}px;
`;

export const RightRailStickyContainer = styled.div`
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    @media (${breakpoint.mdMin}) {
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    @media (${breakpoint.lgMin}) {
        display: block;
        margin-left: auto;
        margin-right: 0;
    }
`;

export const RightRailStickyAd = styled.div.attrs({
  className: 'rr-sticky-hub',
})`
    min-height: 250px;
    position: sticky;
    top: 116px;
    width: 300px;
    margin-bottom: 36px;

    &.is-sticky {
        min-height: 250px;
        position: sticky;
        top: 80px;
        margin-bottom: 36px;
        &.lg {
            top: 94px;
        }
    }

    &.is-sticky-cc-height {
        min-height: 250px;
        position: sticky;
        top: 230px;
        margin-bottom: 36px;
        &.lg {
            top: 244px;
        }
    }
`;

export const RightRailContainer = styled.div`
    display: none;
    min-width: 300px;
    position: relative;
    width: 300px;

    @media (${breakpoint.lgMin}) {
        display: block;
    }

    @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125))
and (${breakpoint.mdMin})
and (${breakpoint.lgMax}) {
    display: block;
}
`;

export const RightRailAd = styled.div`
    min-height: 250px;
    position: relative;
    width: 300px;
`;

interface RightRailSimpleAdProps {
  $page?: string;
}

interface InStreamProps {
  $pageType?: PageType;
}

export const RightRailSimpleAd = styled.div<RightRailSimpleAdProps>`
    width: 300px;
    min-height: 250px;
    position: ${({ $page }) => ($page === 'people' ? 'sticky' : 'none')};
    top: ${({ $page }) => ($page === 'people' ? '60px' : '0px')};
    padding-top: ${({ $page }) => ($page === 'people' ? '32px' : '0px')};;
`;

export const FooterAd = styled.div`
    margin-top: 32px;
    width: 100%;
    padding: 10px;
    min-height: 112px;
    display: flex;
    justify-content: center;
`;

export const FooterToutAd = styled.div`
    text-align: center;
    margin-bottom: 24px;
    margin-left: auto;
    margin-right: auto;
`;

export const InStreamAd = styled.div<InStreamProps>`
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    position: sticky;
    position: -webkit-sticky; /* For Safari */
    min-height: 50px;
    z-index: 5;
    overflow-y: visible;
    top: 175px;

    &.subscriptions {
        min-height: 50px;
        margin: 0 auto;
    }

    &.homepage {
        min-height: 50px;
        margin: var(--Section-Margin) auto;

        @media (${breakpoint.hybridMin}) {
            min-height: 50px;
        }
    }

    &.simple {
        min-height:${({ $pageType }) => ($pageType !== PageType.HUB ? '50px' : '0px')};

        @media (${breakpoint.hybridMin}) {
            min-height: 50px;
        }
    }

    &.narrow {
        min-height: 50px;
    }

    &.simple-homepage {
        display: flex;
        justify-content: center;
        min-height: 50px;
        margin-top: 48px;
        margin-bottom: 0px;

        @media (${breakpoint.mdMin}) {
            min-height: 50px;
        }
    }
`;

export const AdsCenteredDiv = styled.div<{ addPadding?: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${(props) => (props.addPadding ? '16px 0' : '0')};
`;

export const LeaderboardAd = styled.div<{ theme: Theme }>`
    background-color: ${getColor('surfaceWhite')}F2;
    border-top: 0;
    top: 0;
    margin: auto;
    padding: 10px;
    text-align: center;
    width: 100%;
    z-index: 200;
    min-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (${breakpoint.mdMin}) {
        display: flex;
        min-height: 110px;
        position: static;
        width: 100%;
        > div:first-child {
            margin: auto;
            position: sticky;
            top: 10px;
        }
    }

    @media (${breakpoint.lgMin}) {
        min-height: 265px;
    }

    @media ((-webkit-device-pixel-ratio: 1.875) or (-webkit-device-pixel-ratio: 2.125))
and (${breakpoint.mdMin})
and (${breakpoint.lgMax}) {
    min-height: 300px;
}
`;

export const TrackingPlaceholder = styled.div`
    height: 1px;
    width: 1px;
    position: absolute;
`;
