/* eslint-disable import/prefer-default-export */

// ********* List of UI Fragments *********
// This enum is purely semantic, used to
// identify the location of a crash in the UI,
// should one occur.
// Add any important parts of the UI here.

export enum UIFragments {
  MAIN_LAYOUT = 'MAIN_LAYOUT',
  PAGE_CONTENT = 'PAGE_CONTENT',
  WELL_SECTION_PAGE = 'WELL_SECTION_PAGE',
  WELL_REGION = 'WELL_REGION',
  WELL_PEOPLE_RANKING_PAGE = 'WELL_PEOPLE_RANKING_PAGE',
  WELL_SIMPLE_RANKING_PAGE = 'WELL_SIMPLE_RANKING_PAGE',
  HUB_PAGE = 'HUB_PAGE',
  HUB_ARTICLE_SECTION = 'HUB_ARTICLE_SECTION',
  SPONSORED = 'SPONSORED',
  COMPANY_PAGE = 'COMPANY_PAGE',
  COMPANIES_PAGE = 'COMPANIES_PAGE',
  EARNINGS_REPORT = 'EARNINGS_REPORT',
  AUTHOR_PAGE = 'AUTHOR_PAGE',
  ARTICLE_PAGE = 'ARTICLE_PAGE',
  SITEMAP_SECTION = 'SITEMAP_SECTION',
  ARTICLE_CONTENT = 'ARTICLE_CONTENT',
  ADMIN_BAR = 'ADMIN_BAR',
  CONTRIBUTORS_BYLINE = 'CONTRIBUTORS_BYLINE',
  BYLINE_LIST = 'BYLINE_LIST',
  ARTICLE_SERIES = 'ARTICLE_SERIES',
  ARTICLE_MEDIA = 'ARTICLE_MEDIA',
  ARTICLE_TOP_ARTICLES = 'ARTICLE_TOP_ARTICLES',
  AD = 'AD',
  KEYTAKEAWAYS_WIDGET = 'KEYTAKEAWAY_WIDGET',
  AUTHOR_BIO = 'AUTHOR_BIO',
  FOOTER = 'FOOTER',
  ARTICLE_SIDEBAR = 'ARTICLE_SIDEBAR',
  STICKY_CTA = 'STICKY_CTA',
  LANDING_PAGE_HEADER = 'LANDING_PAGE_HEADER',
  TOC = 'TABLE_OF_CONTENTS',
  RANKING_FRANCHISE_PAGE = 'RANKING_FRANCHISE_PAGE',
  RANKING_PAGE = 'RANKING_PAGE',
  RANKING_SEARCH_PAGE = 'RANKING_SEARCH_PAGE',
  SIMPLE_RANKING_PAGE = 'SIMPLE_RANKING_PAGE',
  CRYPTO_HOME_PAGE = 'CRYPTO_HOME_PAGE',
  CRYPTO_CRASH_COURSE_PAGE = 'CRYPTO_CRASH_COURSE_PAGE',
  CRYPTO_CRASH_COURSE_TERM_PAGE = 'CRYPTO_CRASH_COURSE_TERM_PAGE',
  RANKINGS_PAGE = 'RANKINGS_PAGE',
  LONGFORM_PAGE = 'LONGFORM_PAGE',
  MAGAZINE_PAGE = 'MAGAZINE_PAGE',
  NEWSLETTER_PAGE = 'NEWSLETTER_PAGE',
  PEOPLE_PAGE = 'PEOPLE_PAGE',
  MY_ACCOUNT_PAGE = 'MY_ACCOUNT_PAGE',
  MY_ACCOUNT_PAYMENT_PAGE = 'MY_ACCOUNT_PAYMENT_PAGE',
  MY_ACCOUNT_SUBSCRIPTIONS_PAGE = 'MY_ACCOUNT_SUBSCRIPTIONS_PAGE',
  MY_ACCOUNT_TRANSACTIONS_PAGE = 'MY_ACCOUNT_TRANSACTIONS_PAGE',
  EDU_RANKINGS_FILTERS = 'EDU_RANKINGS_FILTERS',
  SEARCH_W_BUTTON = 'SEARCH_W_BUTTON',
  FILTER_CHIPS = 'FILTER_CHIPS',
  EDU_PRODUCT_CARD = 'EDU_PRODUCT_CARD',
  EDU_RELATED_ARTICLES = 'EDU_RELATED_ARTICLES',
  NAVIGATION = 'NAVIGATION',
  SOCIALS = 'SOCIALS',
  WP_BLOCK_TABLE = 'WP_BLOCK_TABLE',
  IFRAME = 'IFRAME',
  SCRIPT = 'SCRIPT',
  SURVEY = 'SURVEY',
  TOP_HIGHLIGHT_CARD_WIDGET = 'TOP_HIGHLIGHT_CARD_WIDGET',
  CONTENT_HIGHLIGHT_CARD_WIDGET = 'CONTENT_HIGHLIGHT_CARD_WIDGET',
  EXPLORE_OUR_PRODUCTS_WIDGET = 'EXPLORE_OUR_PRODUCTS_WIDGET',
  MEET_OUR_TEAM_WIDGET = 'MEET_OUR_TEAM_WIDGET',
  MONTHLY_ARTICLES_WIDGET = 'MONTHLY_ARTICLES_WIDGET',
  PINNED_SUBSECTION_CARD_LIST = 'PINNED_SUBSECTION_CARD_LIST',
  PINNED_ARTICLES_LIST = 'PINNED_ARTICLES_LIST',
  EDU_RANKINGS_SECTION = 'EDU_RANKINGS_SECTION',
  PRODUCT_TABLE = 'PRODUCT_TABLE',
  READ_OUR_REVIEWS_WIDGET = 'READ_OUR_REVIEWS_WIDGET',
  EDU_RANKING_CARDS_LIST = 'EDU_RANKING_CARDS_LIST ',
  EDU_CHILD_RANKINGS = 'EDU_CHILD_RANKINGS',
  LATEST_ARTICLES = 'LATEST_ARTICLES',
  ARTICLES_SECTION = 'ARTICLES_SECTION',
  TOP_ARTICLES = 'TOP_ARTICLES',
  PAGINATION = 'PAGINATION',
  CARD_COMPARISON_DROPDOWN = 'CARD_COMPARISON_DROPDOWN',
  CARD_COMPARISON_CAROUSEL = 'CARD_COMPARISON_CAROUSEL',
  CARD_COMPARISON_STATIC_CONTENT = 'CARD_COMPARISON_STATIC_CONTENT',
  CARD_COMPARISON_STICKY_PANEL = 'CARD_COMPARISON_STICKY_PANEL',
}
