import Slider from 'react-slick';
import PrevArrow from 'components/Arrows/PrevArrow';
import NextArrow from 'components/Arrows/NextArrow';
import { Maximize } from 'icons/Maximize';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FullScreenGalleryWrapper, GalleryWrapper, SlideNumber } from 'components/Gallery/GalleryStyles';
import { useState } from 'react';

export interface GalleryProps {
  children: JSX.Element[];
  infinite?: boolean;
}

const Gallery = ({ children, infinite }: GalleryProps) => {
  const [slide, setSlide] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  const settings = (initialSlide: number) => ({
    afterChange: (currentSlide: number) => {
      setSlide(currentSlide + 1);
    },
    className: 'gallery',
    dots: false,
    infinite: !!infinite,
    initialSlide,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    slidesToScroll: 1,
    slidesToShow: 1,
    speed: 500,
  });

  const slider = (
    <>
      <Slider {...settings(slide - 1)}>{children}</Slider>
      <SlideNumber>
        {slide} of {children.length}
        <span
          role='button'
          className='maximize'
          aria-label='Enable Fullscreen'
          tabIndex={0}
          onClick={() => setFullScreen(true)}
          onKeyDown={() => setFullScreen(true)}
        >
          <Maximize />
        </span>
      </SlideNumber>
    </>
  );
  return !fullScreen ? (
    <GalleryWrapper>{slider}</GalleryWrapper>
  ) : (
    <FullScreenGalleryWrapper>
      <span
        role='button'
        className='close'
        aria-label='Disable Fullscreen'
        tabIndex={0}
        onClick={() => setFullScreen(false)}
        onKeyDown={() => setFullScreen(false)}
      />
      {slider}
    </FullScreenGalleryWrapper>
  );
};

export default Gallery;
