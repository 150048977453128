import { SideNavWrapper } from 'components/Header/Menu/MenuStyles';
import MenuItem from 'components/Header/MenuItem';
import { MenuItemProps } from 'interfaces/navigation/Header';
import { useEffect, useRef, useState } from 'react';

interface Props {
  className?: string;
  currentUrl: string;
  leftMenu: MenuItemProps[];
  rightMenu: MenuItemProps[];
  subDomain?: string;
  isDrawerOpen: boolean;
  onSearchClick: () => void;
  onKeyDown?: () => void;
}

const Menu = ({ className, currentUrl, rightMenu, leftMenu, onKeyDown, isDrawerOpen }: Props) => {
  // const isMobile = useBreakpoint('mdMin');

  const [activeIndex, setActiveIndex] = useState(-1);
  const [isSubmenuActive, setIsSubmenuActive] = useState(false);
  const leftMenuItems = leftMenu.flatMap((item) => {
    if (item.label === 'Sections') return item.subMenu || item;
    return item;
  });
  const mobileMenu = [...leftMenuItems, ...rightMenu];

  const menuRef = useRef<HTMLUListElement>(null);

  const changeState = (classNameUpdate: string, index?: number) => {
    if (!menuRef.current) return;
    menuRef.current?.classList.remove('open');
    menuRef.current?.classList.remove('not-open');
    menuRef.current?.classList.add(classNameUpdate);
    setActiveIndex(index || -1);
  };

  useEffect(() => {
    if (!isDrawerOpen) {
      changeState('not-open');
      setIsSubmenuActive(false);
    }
  }, [isDrawerOpen]);

  return (
    <SideNavWrapper className={className}>
      <ul
        className='menu-list not-open'
        data-cy='menuMobile'
        ref={menuRef}
      >
        {mobileMenu.map((item, index) => (
          <MenuItem
            key={item.id}
            index={index}
            className={`active-${activeIndex}`}
            {...item}
            currentUrl={currentUrl}
            activeIndex={activeIndex}
            onChange={changeState}
            onKeyDown={index === mobileMenu.length - 1 && onKeyDown ? onKeyDown : undefined}
            isSubmenuActive={isSubmenuActive}
            isArrow={() => setIsSubmenuActive((prev) => !prev)}
          />
        ))}
      </ul>
    </SideNavWrapper>
  );
};
export default Menu;
