import Footer, { FooterProps } from 'components/Footer';
import { HeaderNav } from 'interfaces/navigation/Header';
import Header from 'components/Header';
import { LayoutWrapper, LeaderboardAdWrapper, NativoLeaderboard } from 'components/Layout/LayoutStyles';
import { Leaderboard } from 'components/Ad/AdSlot';
import { PageType } from 'interfaces/content/articles/Post';
import RecommendsFooter, { RecommendsFooterProps } from 'components/RecommendsFooter';
import cn from 'classnames';
import { Microsites } from 'constants/constants';
import { Hub } from 'interfaces/Home';
import BottomPaywallContainer from 'components/Paywall/BottomPaywallContainer';
import { graphikCompact, saol, graphikCond } from 'styles/fonts';
import { useRouter } from 'next/router';
import useBreakpoint from 'hooks/useBreakpoint';

// import InhouseAd from 'components/Ad/InhouseAd';

interface LayoutProps {
  children: JSX.Element;
  headerNav: HeaderNav;
  leaderboard: boolean;
  footer?: FooterProps | RecommendsFooterProps;
  hasVanityUrl: boolean | undefined;
  pageType: PageType;
  currentSection: string;
  section?: Hub;
  currentTags: string[];
  currentAuthors?: string;
}

const Layout = (props: LayoutProps) => {
  const {
    children,
    leaderboard,
    footer,
    headerNav,
    hasVanityUrl,
    section,
    currentTags,
    // pageType,
    // currentTags,
    // currentAuthors,
    // currentSection,
  } = props;

  const isRecommends = [Microsites.Recommends, Microsites.Education].includes(headerNav?.subDomain);
  const showRecommendsFooter = process.env.FEATURE_FLAG_RECOMMENDS_FOOTER && isRecommends;
  const router = useRouter();
  const isMobile = useBreakpoint('mdMax');
  const isSubscribeMobilePage = !!(router.asPath.includes('/subscribe/') && isMobile);

  return (
    <div className={cn(saol.variable, graphikCompact.variable, graphikCond.variable)}>
      {leaderboard && (
        <LeaderboardAdWrapper>
          <Leaderboard id={0} />
        </LeaderboardAdWrapper>
      )}
      {hasVanityUrl && <NativoLeaderboard className='nativo-content__leaderboard' />}
      {headerNav && !isSubscribeMobilePage && (
        <Header
          {...headerNav}
          topics={headerNav.topicNavigation}
          currentTags={currentTags}
        />
      )}
      <LayoutWrapper
        $isRecommends={isRecommends}
        $showRecommendsFooter={showRecommendsFooter}
      >
        <main>{children}</main>
        {!showRecommendsFooter && footer && !isSubscribeMobilePage && <Footer {...(footer as FooterProps)} />}
        {showRecommendsFooter && footer && (
          <RecommendsFooter
            {...(footer as RecommendsFooterProps)}
            currentSection={section}
          />
        )}
      </LayoutWrapper>
      <BottomPaywallContainer />
      {/* Sticky Ads (old Digioh 3rd party) are on hold for now  */}
      {/* <InhouseAd */}
      {/*   pageType={pageType} */}
      {/*   placementName='Sticky' */}
      {/*   currentSection={currentSection || ''} */}
      {/*   currentTags={currentTags?.join(',') || ''} */}
      {/*   currentAuthors={currentAuthors || ''} */}
      {/* /> */}
    </div>
  );
};

export default Layout;
