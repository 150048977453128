import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const Wrapper = styled.div<{
  $isMobile?: boolean;
  $isTablet?: boolean;
  theme: Theme;
}>`
  background: ${getColor('surfaceWhite')};
  will-change: transform;
  position: sticky;
  top: var(--cc-sbillboard-min-height, var(--ld-height, 0));
  transform: translateZ(0);
  z-index: 9999;
  height: 80px;
  isolation: isolate;

  @media (${breakpoint.mdMin}) {
    height: 56px;
    margin: 0 auto;
    width: 100%;
  }
`;

export default Wrapper;
