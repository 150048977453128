/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
  AdPlacementConfig,
  AdSlotConfig,
  DisplayAdSlotFunction,
  GetPlacementConfigFunction,
  PageAdConfig,
  PlacementName,
  RegisterAdSlotRenderCallbackFunction,
} from 'interfaces/ads/Ad';
import useIsBrowser from 'hooks/useIsBrowser';
import CustomWindow, { TokenConfig } from 'interfaces/Utils';
import sha256 from 'crypto-js/sha256';
import { UserContext } from 'providers/UserProvider';
import usePageview from './utils/usePageView';
import buildConfig from './utils/buildConfig';
import AdLibrary from './AdLibrary';
import { createAdTagPath } from './utils/createPath';
import { calculateBrowserWidth } from './utils/browser';

export interface AdContextInterface {
  adsEnabled?: boolean;
  initialized: boolean;
  channel: string;
  cid: string | null;
  topics: string[];
  tags: string[];
  ctype: string;
  premiumCategory: string;
  getPlacementConfig: GetPlacementConfigFunction;
  displayAdSlot: DisplayAdSlotFunction;
  registerAdSlotRenderCallback: RegisterAdSlotRenderCallbackFunction;
  setRenderOutbrainScript?: React.Dispatch<React.SetStateAction<boolean>>;
  outbrainScriptLoaded?: boolean;
}

const emptyPlacementConfig: AdPlacementConfig = {
  lazyLoad: false,
  name: 'Footer',
  path: '',
  refreshRate: 60,
  sizeMappings: [],
};

export const defaultAdContext = {
  adsEnabled: false,
  channel: '',
  cid: '',
  ctype: '',
  displayAdSlot: () => {},
  getPlacementConfig: () => emptyPlacementConfig,
  initialized: false,
  premiumCategory: '',
  registerAdSlotRenderCallback: () => {},
  tags: [],
  topics: [],
};

declare let window: CustomWindow;

export const AdContext = React.createContext<AdContextInterface>(defaultAdContext);

export const useAdContext = (pageAdConfig: PageAdConfig, googletagInitialized: boolean): AdContextInterface => {
  const { hasConsent } = useContext(UserContext);
  const isBrowser = useIsBrowser();
  const pv = usePageview(hasConsent);

  const [loaded, setLoaded] = useState<boolean>(false);

  // Function to get the value of a cookie by its name
  function getCookie(name: string): string | null {
    const cookieString: string = document.cookie;
    const cookies: string[] = cookieString.split(';');
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < cookies.length; i++) {
      const cookie: string = cookies[i].trim();
      const [cookieName, cookieValue] = cookie.split('=');
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }
    return null;
  }

  const pagesToUpdateDefaultPath = ['fortune.well', 'fortune.europe', 'fortune.asia', 'fortune.recommends'];

  if (isBrowser && calculateBrowserWidth() < 768) {
    if (pagesToUpdateDefaultPath.includes(pageAdConfig.defaultPath))
      pageAdConfig.defaultPath = `${pageAdConfig.defaultPath}/mobile`;
    else if (pageAdConfig.defaultPath === 'fortune') pageAdConfig.defaultPath = 'fortune/mobile';
  } else if (isBrowser && calculateBrowserWidth() >= 768) {
    if (pagesToUpdateDefaultPath.includes(pageAdConfig.defaultPath))
      pageAdConfig.defaultPath = `${pageAdConfig.defaultPath}/desktop`;
    else if (pageAdConfig.defaultPath === 'fortune') pageAdConfig.defaultPath = 'fortune/desktop';
  }

  if (pageAdConfig.ch === 'homepage') pageAdConfig.ch = 'home';

  useEffect(() => {
    if (isBrowser && googletagInitialized && !loaded) {
      const config = buildConfig(pageAdConfig, pv, !!hasConsent);
      const adLib = AdLibrary.getInstance();
      adLib
        .init(config)
        .then(() => {
          setLoaded(true);
        })
        .catch(() => {
          console.error('Failed to load ad manager');
        });
    }

    if (hasConsent && window.tp && window.tp.pianoId) {
      if (window.tp.pianoId.getUser()) {
        const hashedEmail = sha256(window.tp.pianoId.getUser().email).toString();

        const tokenConfig: TokenConfig = {
          duration: 604800,
          hashedRecords: [{ record: hashedEmail, type: 'email' }],
          optOut: false,
        };

        const cookieCheck = getCookie('AMZN-Token');
        if (cookieCheck === null && window.apstag && window.apstag.init && typeof window.apstag.rpa === 'function') {
          window.apstag.rpa(tokenConfig, () => {}, true);
        } else if (
          cookieCheck !== null &&
          window.apstag &&
          window.apstag.init &&
          typeof window.apstag.upa === 'function'
        ) {
          window.apstag.upa(tokenConfig, () => {}, true);
        }
      }
    } else if (getCookie('AMZN-Token')) {
      // if the user has not given consent, we need to call the dpa function to disable personalized ads
      window.apstag.dpa();
    }
  }, [pageAdConfig, pv, hasConsent, isBrowser, loaded, googletagInitialized]);

  return useMemo(() => {
    if (isBrowser && loaded) {
      return {
        adsEnabled: true,
        channel: pageAdConfig.ch,
        cid: pageAdConfig.cid,
        ctype: pageAdConfig.ctype,
        displayAdSlot: (slotConfig: AdSlotConfig) => {
          const adLib = AdLibrary.getInstance();
          return adLib.displayAdSlot(slotConfig);
        },
        getPlacementConfig: (name: PlacementName) => {
          const adLib = AdLibrary.getInstance();
          return adLib.getPlacementConfig(name);
        },
        initialized: true,
        premiumCategory: pageAdConfig.premiumCategory || '',
        registerAdSlotRenderCallback: (id: string, callback: (event: any) => void) => {
          const adLib = AdLibrary.getInstance();
          return adLib.registerAdSlotRenderCallback(id, callback);
        },
        tags: pageAdConfig.tags,
        topics: pageAdConfig.topics,
      };
    }

    return defaultAdContext;
  }, [isBrowser, loaded, pageAdConfig]);
};

export const useAdTag = (pathname: string): string => {
  const { hasConsent } = useContext(UserContext);
  const { channel, cid, topics, tags, ctype, premiumCategory } = useContext(AdContext);

  const adTagPath = createAdTagPath(channel, pathname);
  const adTagUrl =
    'pubads.g.doubleclick.net/gampad/ads' +
    `?iu=/21809533738/${adTagPath}` +
    `&description_url=${encodeURIComponent(pathname)}` +
    '&env=vp' +
    '&impl=s' +
    '&correlator=' +
    '&tfcd=0' +
    `&npa=${hasConsent ? '0' : '1'}` +
    '&gdfp_req=1' +
    '&output=vast' +
    '&sz=640x360' +
    '&ciu_szs=300x250,728x90' +
    '&unviewed_position_start=1' +
    '&cust_params=' +
    `cid=${cid}` +
    `%26topics=${encodeURIComponent(topics?.join(',') || '')}` +
    `%26tags=${encodeURIComponent(tags?.join(',') || '')}` +
    `%26ctype=${ctype}` +
    `%26premiumCategory=${premiumCategory}`;

  return adTagUrl;
};
