import { AllHTMLAttributes, JSXElementConstructor, ReactElement, useMemo, useRef, useState } from 'react';
import { Disclosure } from 'api/MicrositeConfig/getMicrositeConfig';
import RawHtml from 'utils/miscUtils/rawHtml';
import useClickOutside from 'hooks/useClickOutside';
import { Microsites } from 'constants/constants';
import { COMMON_TEST_IDS } from 'constants/testsIds/common';
import * as S from './DisclosureStripeStyles';

export interface DisclosureStripeProps {
  content: Disclosure;
  templateType?: string;
  subDomain?: string;
  className?: string;
}

const DisclosureStripe = ({ content, templateType, subDomain, className }: DisclosureStripeProps) => {
  const landingPageClass = templateType === 'landing_page' ? 'landingPage' : '';
  const cryptoDisclosureClass = Microsites.Crypto === subDomain ? 'crypto' : '';
  const [showFullText, setShowFullText] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useClickOutside(dropdownRef, () => setShowFullText(false));

  const { shortText, fullText } = content;

  const fullTextExists = fullText !== '';

  const updatedFullText = useMemo(() => {
    const fullTextElements = RawHtml({ html: fullText });

    if (Array.isArray(fullTextElements)) {
      return fullTextElements.map((elem: ReactElement<any, string | JSXElementConstructor<any>>) => {
        const elemProps = elem.props as AllHTMLAttributes<ReactElement>;
        if (elemProps && elemProps.href) {
          return {
            ...elem,
            props: { ...elemProps, rel: 'noopener noreferrer', role: 'link', target: '_blank' },
          };
        }
        return elem;
      });
    }
    return fullTextElements;
  }, [fullText]);

  const showFullDisclosure = () => {
    setShowFullText(true);
  };

  const hideFullDisclosure = () => {
    setShowFullText(false);
  };

  return (
    <S.Container
      className={`${landingPageClass} ${cryptoDisclosureClass} ${className}`}
      data-cy={COMMON_TEST_IDS.DISCLOSURE_STRIPE}
    >
      <S.Wrapper>
        {RawHtml({ html: shortText })}{' '}
        <S.DropdownContainer ref={dropdownRef}>
          {fullTextExists && <S.ButtonAsLink onClick={showFullDisclosure}> Learn more. </S.ButtonAsLink>}
          {showFullText && (
            <S.Dropdown>
              <S.DropdownHeader>
                <button
                  type='button'
                  className='close'
                  aria-label='Hide Disclosure'
                  onClick={hideFullDisclosure}
                  onKeyDown={(e) => {
                    if (e.key !== 'Tab') {
                      hideFullDisclosure();
                    }
                  }}
                />
              </S.DropdownHeader>
              <div
                role='presentation'
                onKeyDown={(e) => {
                  if (e.key !== 'Tab' && e.key !== 'Enter') {
                    hideFullDisclosure();
                  }
                }}
              >
                {updatedFullText}
              </div>
            </S.Dropdown>
          )}
        </S.DropdownContainer>
      </S.Wrapper>
    </S.Container>
  );
};

export default DisclosureStripe;
