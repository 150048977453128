import BLURRED_GRAY_PLACEHOLDER from 'lib/mockData/Image/placeholderUrl';

export const handleBrokenImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
  try {
    const target = event.currentTarget;
    if (target.src !== BLURRED_GRAY_PLACEHOLDER) {
      target.src = BLURRED_GRAY_PLACEHOLDER; // Set the placeholder image
      target.srcset = ''; // Clear srcset to ensure only the placeholder is used
    }
  } catch (error) {
    console.error('Error handling broken image:', error);
  }
};

export default handleBrokenImage;
