/* eslint-disable @next/next/next-script-for-ga */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-invalid-html-attribute */
import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { PianoPageConfig } from 'services/Piano/entities/PianoPageConfig';
import { addPaywallSchema } from 'utils/paywallUtils';
import { formatAmpersand } from 'utils/miscUtils/myFavorites';
import { PageType } from 'interfaces/content/articles/Post';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { addStnMetaSchema } from 'utils/metaDataUtils/setJsonLdSchema';
import { UserContext } from 'providers/UserProvider';
import { commonColors } from 'styles/colors';
import { objectToLiteralString } from 'utils/stringUtils';
import CustomWindow from 'interfaces/Utils';
import customOneTrustLocation from 'utils/miscUtils/customOneTrustLocation';
import Cookies from 'js-cookie';
import { SegmentPageviewDataProps, segmentPageviewProps } from 'utils/trackDataUtils';
import { JsonLdSchema } from 'interfaces/metaDataUtils/jsonLdSchema';
import { SubscriberContext } from 'services/Subscriber';

export interface HeadProps {
  authors?: string;
  databaseId: string;
  dateGmt: string | null;
  description: string;
  faqSchema: string;
  hasStnVideo: boolean;
  hasVanityUrl?: boolean;
  image: string;
  imageTwitter?: string;
  jsonLdSchema: string;
  keywords: string;
  link: string;
  pageAdConfig?: PageAdConfig;
  pianoPageConfig: PianoPageConfig;
  premiumCategory: string;
  primarySection: string;
  socialDescription: string;
  socialTitle: string;
  tagNames: string[];
  title: string;
  pageType: PageType;
  canonicalUrl: string | null;
  redVenture?: boolean;
  siteName?: string;
  subDomain?: string;
  useBinanceWidget?: boolean;
  rssFeedLink: string | null;
  widgetCss?: string | null;
  prefixedDatabaseId?: string | null;
  noIndex?: boolean | null;
  templateType?: string;
}
interface OneTrustUser {
  dataSubjectParams?: {
    id: string;
    isAnonymous: boolean;
    token: string;
  };
  geolocationResponse?: {
    stateCode: string;
    countryCode: string;
    regionCode?: string;
  };
}

declare let window: CustomWindow;
const Head = (props: HeadProps) => {
  const router = useRouter();
  const referrer = useRef<string>('');
  const pageTitle = useRef<string>('');
  const queryParamsRef = useRef<string>('');
  const { userState: user, setHasConsent } = useContext(UserContext);
  const [isMounted, setIsMounted] = useState(false);
  const [loadOneTrust, setLoadOneTrust] = useState(false);
  const [oneTrustUserObj, setOneTrustUserObj] = useState<OneTrustUser | null | undefined>(null);
  const [consent, setConsent] = useState<boolean | null>(null);

  const {
    authors,
    pageType,
    databaseId,
    dateGmt,
    description,
    prefixedDatabaseId,
    faqSchema,
    hasStnVideo,
    hasVanityUrl,
    image,
    imageTwitter,
    jsonLdSchema,
    keywords,
    widgetCss,
    link,
    noIndex,
    pageAdConfig,
    pianoPageConfig,
    premiumCategory,
    primarySection,
    socialDescription,
    socialTitle,
    title,
    tagNames,
    canonicalUrl,
    redVenture,
    siteName = 'Fortune',
    subDomain,
    useBinanceWidget,
    rssFeedLink = '',
    templateType,
  } = props;

  const segmentPageviewData: SegmentPageviewDataProps = {
    authors,
    databaseId,
    dateGmt,
    hasStnVideo,
    pageType: templateType === 'feature' ? PageType.FORTUNE500_QA_ARTICLE : pageType,
    prefixedDatabaseId,
    premiumCategory,
    primarySection,
    subDomain,
    tagNames,
  };

  let initialCanonical;

  if (canonicalUrl && canonicalUrl !== '') {
    initialCanonical = canonicalUrl;
  } else {
    initialCanonical = link;
  }
  const [canonical, setCanonical] = useState(initialCanonical);

  // References to Piano user handler and OneTrust mount state
  // Used for a watchdog timer that mounts OneTrust if Piano doesn't load in time
  // This ensures consent management is mounted if Piano gets stuck
  // (check fetchToken call below)
  const userContextRef = useRef<SubscriberContext>(user);
  userContextRef.current = user;
  const hasInitializedOneTrust = useRef<boolean>(loadOneTrust);
  hasInitializedOneTrust.current = loadOneTrust;

  useEffect(() => {
    if (hasVanityUrl) {
      setCanonical(window.location.href);
    }
  }, [hasVanityUrl]);

  useEffect(() => {
    setIsMounted(true);

    /* eslint-disable */
    // Initialize VWO if consent is given
    window.VWO = window.VWO || [];
    window.VWO.init =
      window.VWO.init ||
      function (state: any) {
        window.VWO.consentState = state;
      };
    // 1 - Accepted // 2 - Pending // 3 - Denied
    window.VWO.init(2);
    /* eslint-enable */

    queryParamsRef.current = window.location.search;
    referrer.current = document.referrer;
    pageTitle.current = document.title;
  }, []);

  useEffect(() => {
    const fetchToken = async () => {
      if (user.isLoggedIn && user.pianoUid) {
        const response = await fetch(`${process.env.SITE_BASE_URL}/api/getJwtToken/${user.pianoUid}/`);
        if (!response.ok) {
          throw new Error('Could not get Piano user JWT');
        }
        const userToken = (await response.json()) as string;
        setOneTrustUserObj({
          dataSubjectParams: {
            id: user.pianoUid,
            isAnonymous: false,
            token: userToken,
          },
        });
      } else if (process.env.ENVIRONMENT === 'qa' && router.query.customGeolocation) {
        Cookies.set('customGeolocation', router.query.customGeolocation as string);
        const geolocationByQuery = customOneTrustLocation(router.query.customGeolocation as string);
        setOneTrustUserObj({
          geolocationResponse: geolocationByQuery,
        });
      } else if (process.env.ENVIRONMENT === 'qa' && Cookies.get('customGeolocation')) {
        const geolocationByCookie = customOneTrustLocation(Cookies.get('customGeolocation') as string);
        setOneTrustUserObj({
          geolocationResponse: geolocationByCookie,
        });
      } else {
        // Default case for non-logged-in user
        setOneTrustUserObj(undefined);
      }
    };

    if (user.isLoggedIn !== null) {
      // Attempt to load user data if the user service has loaded
      fetchToken()
        .catch((error) => {
          console.error('Error during fetchToken execution, clearing user object -', error);
          // Clear user object on exception
          setOneTrustUserObj(undefined);
        })
        // We only require OneTrust to *wait* for the user object in order to sync
        // Load OneTrust once fetching ends, regardless of outcome
        .finally(() => {
          setLoadOneTrust(true);
        });
    } else {
      // Otherwise, set a watchdog timer
      // When the timer expires, if the user service is not functioning as expected
      // (null value on login flag), mount OneTrust without a user object
      // This ensures we load OneTrust regardless of Piano working or not
      // This mechanism is required as in normal circumstances, we need to wait for Piano to get
      // the user ID so that OneTrust can fetch their preferences
      // However, if Piano gets stuck, we don't want to run our website without consent management
      // (which would incur no tracking, no ads etc)
      setTimeout(() => {
        // This side-effect might've already ran again and mounted OneTrust in the meantime
        // Ensure we only run the code if OneTrust has not, in fact, been set to load yet
        if (!hasInitializedOneTrust.current) {
          // If the user context still shows a null value on its login flag, mount OneTrust directly
          if (userContextRef.current.isLoggedIn === null) {
            console.error(
              'Could not load user identity provider in time, mounting consent provider with no user object',
            );
            setOneTrustUserObj(undefined);
            setLoadOneTrust(true);
          }
        }
      }, 5000);
    }
  }, [user.isLoggedIn, user.pianoUid]);

  useEffect(() => {
    if (loadOneTrust) {
      window.OptanonWrapper = () => {
        const requiredFlags = ['C0001', 'C0002', 'C0003', 'C0004', 'C0005'];
        const activeGroups = window.OnetrustActiveGroups?.split(',') || [];
        const hasRequiredFlags = requiredFlags.every((flag) => activeGroups.includes(flag));
        setConsent(hasRequiredFlags);
      };
    }
  }, [loadOneTrust]);

  useEffect(() => {
    if (consent !== null) {
      setHasConsent(consent);
      /* eslint-disable */
      // Initialize VWO if consent is given
      window.VWO = window.VWO || [];
      window.VWO.init =
        window.VWO.init ||
        function (state: any) {
          window.VWO.consentState = state;
        };
      // 1 - Accepted // 2 - Pending // 3 - Denied
      if (consent) {
        window.VWO.init(1);
      }
      /* eslint-enable */
    }
  }, [consent]);

  let jsonLdSchemaFinal;
  if (
    pianoPageConfig &&
    [
      PageType.ARTICLE,
      PageType.COMPANY,
      PageType.LONGFORM,
      PageType.FRANCHISE_LIST,
      PageType.FRANCHISE_LIST_ITEM,
      PageType.FRANCHISE_LIST_ITEM_COMPANY,
      PageType.FRANCHISE_LIST_SEARCH,
    ].includes(pianoPageConfig.pageType)
  ) {
    jsonLdSchemaFinal = addPaywallSchema(jsonLdSchema, pianoPageConfig);
  } else {
    jsonLdSchemaFinal = jsonLdSchema;
  }

  if (pageAdConfig && jsonLdSchemaFinal && (JSON.parse(jsonLdSchemaFinal) as JsonLdSchema).stnMeta) {
    jsonLdSchemaFinal = addStnMetaSchema(jsonLdSchemaFinal, pageAdConfig);
  }

  function getRobotsContent() {
    const robotsMetaContent: String[] = [];

    if ([PageType.EVERGREEN, PageType.ARTICLE].includes(pageType)) {
      robotsMetaContent.push('max-image-preview:large');
    }

    return robotsMetaContent.toString().replaceAll(',', ', ');
  }

  function setUserObjScriptVal(obj: OneTrustUser | null | undefined) {
    if (obj) {
      return objectToLiteralString(obj);
    }
    return "''";
  }

  const segmentWriteKey = process.env.SEGMENT_WRITE_KEY;
  // const gtmAuth = process.env.GTM_AUTH;
  // const gtmPreview = process.env.GTM_PREVIEW;
  const dataDomainScriptId = process.env.ONE_TRUST_DOMAIN_ID;
  const oneTrustScript = `https://cdn.cookielaw.org/consent/${dataDomainScriptId}/otSDKStub.js`;
  const shouldHaveAuthorMeta = authors && [PageType.ARTICLE, PageType.EVERGREEN, PageType.LONGFORM].includes(pageType);

  function searchQueryCheck() {
    const searchQueryRoutes = [
      '/ranking/[franchiseSlug]/search',
      '/ranking-v2/[franchiseSlug]',
      '/ranking/[franchiseSlug]',
    ];

    if (searchQueryRoutes.includes(router.route) && Object.keys(router.query).length > 1) {
      return true;
    }
    return false;
  }

  return (
    <NextHead>
      <title>{formatAmpersand(title)}</title>
      <meta
        name='msapplication-TileColor'
        content='#ffc40d'
      />
      <meta
        name='theme-color'
        content={commonColors.surfaceWhite}
      />
      <meta
        name='description'
        content={description}
      />
      {shouldHaveAuthorMeta && (
        <meta
          name='author'
          content={authors}
        />
      )}
      <meta
        name='keywords'
        content={keywords}
      />
      <meta
        property='og:url'
        content={link}
      />
      <meta
        property='og:type'
        content='article'
      />
      <meta
        property='og:title'
        content={socialTitle}
      />
      <meta
        property='og:description'
        content={socialDescription}
      />
      <meta
        property='og:site_name'
        content={siteName}
      />
      <meta
        property='og:image'
        content={image}
      />
      <meta
        property='og:width'
        content='1024'
      />
      <meta
        property='og:height'
        content='683'
      />
      <meta
        name='twitter:card'
        content='summary_large_image'
      />
      <meta
        name='twitter:title'
        content={socialTitle}
      />
      <meta
        name='twitter:description'
        content={socialDescription}
      />
      <meta
        name='twitter:image'
        content={imageTwitter || image}
      />
      <meta
        name='twitter:url'
        content={link}
      />
      <meta
        property='fb:pages'
        content='56501027948'
      />
      <meta
        name='robots'
        content={getRobotsContent()}
      />
      {router.route === '/advanced-search' && (
        <meta
          name='robots'
          content='noindex, nofollow'
        />
      )}
      {noIndex === true && (
        <meta
          name='robots'
          content='noindex'
        />
      )}
      {searchQueryCheck() && (
        <meta
          name='robots'
          content='noindex'
        />
      )}
      {widgetCss && (
        <style type='text/css'>
          {`
            ${widgetCss}
          `}
        </style>
      )}
      {/* <link
        rel='preconnect'
        href='https://www.googletagmanager.com'
        crossOrigin=''
      /> */}
      <link
        rel='preconnect'
        href='https://www.google-analytics.com'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://www.googletagservices.com'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://ad.doubleclick.net'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://static.doubleclick.net'
        crossOrigin=''
      />
      <link
        rel='preconnect'
        href='https://securepubads.g.doubleclick.net'
        crossOrigin=''
      />
      <link
        rel='icon'
        href='/icons/favicons/favicon.ico'
      />
      <link
        rel='canonical'
        href={canonical}
      />
      {siteName === 'Fortune Recommends' && (
        <link
          rel='preload'
          href='https://fortune.com/fonts/GraphikCondensed-Regular-Web.woff2'
          as='font'
          crossOrigin=''
          type='font/woff2'
        />
      )}
      {redVenture && (
        <link
          rel='preconnect'
          href='https://static.myfinance.com'
          crossOrigin=''
        />
      )}
      {useBinanceWidget && (
        <link
          rel='preconnect'
          href='https://public.bnbstatic.com/'
          crossOrigin=''
        />
      )}
      {rssFeedLink && (
        <link
          rel='alternate'
          type='application/rss+xml'
          title='Fortune RSS'
          href={rssFeedLink}
        />
      )}
      {faqSchema && (
        <script
          id='faq-json-schema'
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: faqSchema }}
        />
      )}
      {jsonLdSchemaFinal && (
        <script
          id='json-schema'
          type='application/ld+json'
          dangerouslySetInnerHTML={{ __html: jsonLdSchemaFinal }}
        />
      )}
      <script>window.dataLayer = window.dataLayer || []</script>
      {loadOneTrust && (
        <>
          <script
            id='OneTrustUserObject'
            type='text/javascript'
            data-cy='one-trust-user'
          >
            var OneTrust = {setUserObjScriptVal(oneTrustUserObj)};
          </script>
          <script
            async
            src={oneTrustScript}
            data-document-language='true'
            type='text/javascript'
            data-domain-script={dataDomainScriptId}
            data-cy='one-trust-script'
          />
        </>
      )}
      {/* Temporary removed due to some loading ads issues on certain pages */}
      {/* {isMounted && (
        <>
          <script>{"window.grumi = { key: 'f4c1124c-8d38-477a-ad8d-f637a3802f32' }"}</script>
          <script
            src='//rumcdn.geoedge.be/f4c1124c-8d38-477a-ad8d-f637a3802f32/grumi-ip.js'
            async
          />
        </>
      )} */}
      {consent && segmentWriteKey && isMounted && (
        <script
          id='segment'
          dangerouslySetInnerHTML={{
            __html: `!function(){
                var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","screen","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware","register"];analytics.factory=function(e){return function(){if(window.analytics.initialized)return window.analytics[e].apply(window.analytics,arguments);var i=Array.prototype.slice.call(arguments);if(["track","screen","alias","group","page","identify"].indexOf(e)>-1){var c=document.querySelector("link[rel='canonical']");i.push({__t:"bpc",c:c&&c.getAttribute("href")||void 0,p:location.pathname,u:location.href,s:location.search,t:document.title,r:document.referrer})}i.unshift(e);analytics.push(i);return analytics}};for(var i=0;i<analytics.methods.length;i++){var key=analytics.methods[i];analytics[key]=analytics.factory(key)}analytics.load=function(key,i){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=i};analytics._writeKey='${segmentWriteKey}';;analytics.SNIPPET_VERSION="5.2.0"; 
              analytics.load('${segmentWriteKey}');
              analytics.page("${pageTitle.current}",
              ${segmentPageviewProps(segmentPageviewData, referrer.current, queryParamsRef.current, user?.isLoggedIn)});
              }}();`,
          }}
        />
      )}
      {/* this script needs to load after the segment sdk finishes loading;
      it is needed for an indefinite period of time;
      script is doing a local storage cleanup due to a corrupted identifier being persisted there*/}
      {consent && segmentWriteKey && isMounted && (
        <script
          id='segment-afterload-userId-deletion'
          dangerouslySetInnerHTML={{
            __html: `
            // Function to check if userId exists
            function checkUserId() {
                if (analytics.user().id()) {
                    analytics.reset();
                }
            }
    
            // Call the function to check userId after the Segment SDK is fully loaded
            analytics.ready(function () {
                checkUserId();
            });
            `,
          }}
        />
      )}
      {/* {gtmAuth && gtmPreview && (
        <script
          id='gtm'
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+ '&gtm_auth=${gtmAuth}&gtm_preview=${gtmPreview}&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-N68KRG3');`,
          }}
        />
      )} */}

      <link
        rel='preconnect'
        href='https://dev.visualwebsiteoptimizer.com'
      />

      <link
        rel='dns-prefetch'
        href='https://c.amazon-adsystem.com'
      />
      <link
        rel='preconnect'
        href='https://c.amazon-adsystem.com'
        crossOrigin=''
      />
      <script
        async
        src='https://c.amazon-adsystem.com/aax2/apstag.js'
      />
    </NextHead>
  );
};

export default Head;
