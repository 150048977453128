import RawHtml from 'utils/miscUtils/rawHtml';
import EDUCATION_RANKINGS_TEST_IDS from 'constants/testsIds/EducationRankings';
import { FooterDisclaimerWrapper } from './FooterStyles';

export interface FooterDisclaimerProps {
  disclaimer: string;
}

const FooterDisclaimer = (props: FooterDisclaimerProps) => {
  const { disclaimer } = props;

  return (
    <FooterDisclaimerWrapper data-cy={EDUCATION_RANKINGS_TEST_IDS.FOOTER_DISCLAIMER}>
      {RawHtml({ html: disclaimer })}
    </FooterDisclaimerWrapper>
  );
};

export default FooterDisclaimer;
