import { Logo } from 'icons/Logo';
import { SocialList } from 'components/SocialList';
import FooterDisclaimer from 'components/Footer/FooterDisclaimer';
import cn from 'classnames';
import { graphikCompact, graphikCond, saol } from 'styles/fonts';
import Error500 from 'components/Error500';
import * as S from './Error500StandaloneStyles';

const Error500Standalone = () => (
  <S.Wrapper className={cn(saol.variable, graphikCompact.variable, graphikCond.variable)}>
    <S.Header>
      <Logo />
    </S.Header>

    <Error500 />

    <S.Footer>
      <SocialList
        items={[
          {
            type: 'facebook',
            url: 'https://www.facebook.com/FortuneMagazine/',
          },
          {
            type: 'twitter',
            url: 'https://x.com/FortuneMagazine/',
          },
          {
            type: 'linkedin',
            url: 'https://www.linkedin.com/company/fortune',
          },
          {
            type: 'instagram',
            url: 'https://www.instagram.com/fortunemag/',
          },
          {
            type: 'pinterest',
            url: 'https://www.pinterest.com/fortunemagazine/',
          },
        ]}
      />
      <FooterDisclaimer
        disclaimer='© 2022 Fortune Media IP Limited. All Rights Reserved. Use of this site constitutes acceptance of our Terms of Use and Privacy Policy | CA Notice at Collection and Privacy Notice | Do Not Sell My Personal Information | Ad Choices 
                                    FORTUNE is a trademark of Fortune Media IP Limited, registered in the U.S. and other countries. FORTUNE may receive compensation for some links to products and services on this website. Offers may be subject to change without notice.
                                    S&P Index data is the property of Chicago Mercantile Exchange Inc. and its licensors. All rights reserved. Terms & Conditions. Powered and implemented by Interactive Data Managed Solutions.'
      />
    </S.Footer>
  </S.Wrapper>
);

export default Error500Standalone;
