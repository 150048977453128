import React, { useContext, useEffect, useState } from 'react';
import { OutbrainWidget } from 'react-outbrain-widget';
import useBreakpoint from 'hooks/useBreakpoint';
import { UserPaywallContext } from 'providers/PaywallProvider';
import { UserContext } from 'providers/UserProvider';
import { AdContext } from 'services/Ad';
import useOutbrainIndex from 'hooks/useOutbrainIndex';
import OutbrainStyles from './OutbrainStyles';

export interface OutbrainProps {
  permalink: string;
  placement: 'footer' | 'inline';
  section?: string;
  replacedAdIndex?: number;
}

interface WidgetIdMap {
  [widgetPlacement: string]: {
    [breakpoint: string]: {
      [content: string]: string;
    };
  };
}

const widgetIdMap: WidgetIdMap = {
  footer: {
    desktop: {
      'no-paywall': 'AR_1',
      paywalled: 'AR_2',
    },
    mobile: {
      'no-paywall': 'MB_1',
      paywalled: 'MB_2',
    },
  },
  inline: {
    desktop: {
      Lifestyle: 'AR_5',
      Retail: 'AR_4',
    },
    mobile: {
      Lifestyle: 'MB_5',
      Retail: 'MB_4',
    },
  },
};

const Outbrain: React.FC<OutbrainProps> = ({ permalink, placement, replacedAdIndex, section }: OutbrainProps) => {
  const { hasConsent, hideOutbrain } = useContext(UserContext);
  const { paywallCheck } = useContext(UserPaywallContext).paywallState;
  const { outbrainScriptLoaded, setRenderOutbrainScript } = useContext(AdContext);
  const breakpoint = useBreakpoint('mmMax') ? 'mobile' : 'desktop';
  const [widgetId, setWidgetId] = useState('');
  const [externalWidgetId, setExternalWidgetId] = useState('');
  const widgetIndex = useOutbrainIndex();
  const placementInfo = placement === 'footer' ? 'outbrain' : `in-stream-${replacedAdIndex}`;

  useEffect(() => {
    if (hasConsent && !outbrainScriptLoaded && setRenderOutbrainScript) {
      setRenderOutbrainScript(true);
    }
  }, [hasConsent, outbrainScriptLoaded, setRenderOutbrainScript]);

  useEffect(() => {
    if (paywallCheck !== 'pending') {
      if (placement === 'footer') {
        setWidgetId(widgetIdMap.footer[breakpoint][paywallCheck]);
      } else if (placement === 'inline' && section && ['Lifestyle', 'Retail'].includes(section)) {
        const id = widgetIdMap.inline[breakpoint][section];
        setWidgetId(id);
        setExternalWidgetId(`${section}Feed_${id}`);
      }
    }
  }, [breakpoint, paywallCheck, placement, section]);

  // Outbrain should only load if user consents to advertising tags
  // and if it is not being actively suppressed (suppression only applies to footer placement).
  const showOutbrain = hasConsent && !(placement === 'footer' && hideOutbrain) && outbrainScriptLoaded && widgetId;

  return (
    <OutbrainStyles
      className={`impression-element ${placement} ${showOutbrain ? '' : 'hidden'}`}
      data-content-placement={placementInfo}
    >
      {showOutbrain && (
        <OutbrainWidget
          dataSrc={permalink}
          dataWidgetId={widgetId}
          key={widgetId}
          widgetIndex={widgetIndex}
          externalWidgetId={externalWidgetId || widgetId}
        />
      )}
    </OutbrainStyles>
  );
};

export default Outbrain;
