import styled from 'styled-components';
import CustomLink from 'components/Globals/Base/Link';
import DangerIcon from 'icons/DangerIcon';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const ExpiredSubAlertWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  z-index: 1;
  min-height: 40px;
  background: ${getColor('shadeSemanticError100')};
  border-top: 1px solid ${getColor('borderAccentSecondary')};
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
  @media (${breakpoint.mdMin}) {
    margin: 0px 24px;
  }
  @media (${breakpoint.lgMin}) {
    margin: 0px 48px;
  }
  @media (${breakpoint.xlMin}) {
    margin: 0 48px;
  }

  @media (${breakpoint.xxlMin}) {
    max-width: 1344px;
    margin: 0 auto;
    padding: 0px;
  }
  `;

export const ExpiredContent = styled.div`
  height: 100%;
  font-family: ${font.graphikCond};
  font-size: 16px;
  display: flex;
  align-items: center;
  margin-left: 8px;  
  @media (${breakpoint.mmMin}) {
    margin-left: 20px;
  }
`;

export const ExpiredDangerIcon = styled(DangerIcon)`
  margin-right: 8px;
`;

export const ExpiredCopy = styled.div`
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-left: 8px;
`;

export const RenewLink = styled(CustomLink)<{ theme: Theme }>`
  text-decoration: underline;
  color: ${getColor('textLink')};
`;

export const CloseButton = styled.button`
  display: inline-block;
  height: fit-content;
  width: fit-content;
  justify-self: end;
  padding: 2px 4px;
  margin: auto 20px auto 0px;
`;
