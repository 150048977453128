import styled from 'styled-components';
import { getColor, Theme } from 'styles/theme';

export const NavigationMobileContent = styled.nav<{ theme: Theme }>`
  display: flex;
  align-items: center;
  padding: 23px 20px;
  height: 80px;
  position: relative;
  border-bottom: 1px solid ${getColor('borderPrimary')};
  justify-content: space-between;

  .logo {
    height: 24px;
    cursor: pointer;
  }

  .crypto-logo {
    height: 24px;
    width: 170px;
  }

  .well-logo {
    height: 24px;
    width: 178px;
    cursor: pointer;
  }
  
  .subscribe-cta-mobile {
    width: 100px;
    height: 40px;
  }
`;

export const LeftSide = styled.div`
  display: flex;
  align-items: center;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
`;

export default NavigationMobileContent;
