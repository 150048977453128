import useBreakpoint from 'hooks/useBreakpoint';
import Link from 'components/Globals/Base/Link';
import Button from 'components/Globals/Base/Button';
import { trackEvent } from 'services/Gtm/functions';
import { UserContext } from 'providers/UserProvider';
import { ForwardedRef, forwardRef, useContext } from 'react';
import { Microsites } from 'constants/constants';
import { DrawerToggleButton } from 'components/Header/HeaderStyles';
import BackToFortune from 'components/recommends/BackToFortune';
import { Logo } from 'icons/Logo';
import { showInstreamAd } from 'components/NavigationBar';
import DRAWER_MENU_TEST_IDS from 'constants/testsIds/drawerMenu';
import showLogin from 'services/Piano/showLogin';
import { SignInWrapper } from 'components/Header/Menu/MenuStyles';
import * as S from './DrawerMenuStyles';

interface DrawerMenuProps {
  children: JSX.Element | JSX.Element[];
  subDomain?: string;
  handleClick: () => void;
  onSearchClick: () => void;
  keyboardToggle: () => void;
}

const DrawerMenu = (
  { children, handleClick, keyboardToggle, onSearchClick, subDomain }: DrawerMenuProps,
  ref: ForwardedRef<HTMLDivElement> | null,
) => {
  // const isTablet = useBreakpoint('mdMin');
  const isMobile = useBreakpoint('mdMax');
  const { userState: user, pianoScriptFailed } = useContext(UserContext);

  const isRecommendsOrEdu =
    !process.env.FEATURE_FLAG_DISABLE_RECOMMENDS &&
    [Microsites.Recommends, Microsites.Education].includes(String(subDomain));

  const isSubscribeBtnDisplayed = !user.hasAccess;

  const subscribeBtnTrackingData = {
    contentPlacementCD: 'header',
    eventAction: 'top header links',
    eventCategory: 'navigation',
    eventLabel: 'subscribe now',
    loggedIn: user?.isLoggedIn,
  };

  const closeIconTrackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: 'Global Side navigation toggle',
    eventCategory: 'navigation',
    eventLabel: 'close',
  };
  const trackingData = {
    contentPlacementCD: 'header',
    eventAction: 'top header links',
    eventCategory: 'navigation',
  };

  // const homepageLinkTrackingData = {
  //   contentPlacementCD: 'Global Side navigation',
  //   eventAction: 'Global Side navigation - level 1',
  //   eventCategory: 'navigation',
  //   eventLabel: 'Home Page',
  // };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLLabelElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleClick();
      keyboardToggle();
    }
  };

  return (
    <>
      <S.ContainerDrawer
        className='drawer-menu'
        ref={ref}
        data-cy={DRAWER_MENU_TEST_IDS.CONTAINER}
        $isLogIn={user.isLoggedIn || false}
        $isSubscribeBtn={isSubscribeBtnDisplayed}
      >
        <S.CloseWrapper>
          <S.TopWrapper $isSubscribeBtn={isSubscribeBtnDisplayed}>
            <S.TopLeftWrapper>
              <DrawerToggleButton
                htmlFor='drawer-toggle'
                id='drawer-toggle-button'
                className='close-menu'
                onClick={() => {
                  if (trackEvent && closeIconTrackingData) trackEvent(closeIconTrackingData);
                }}
                onKeyDown={handleKeyDown}
                aria-label='Close menu'
                data-cy='closeButton'
                tabIndex={0}
              />

              <Link
                href='/'
                ariaLabel='Homepage'
                className='mobile-logo'
                dataCy='logo'
              >
                <Logo />
              </Link>
            </S.TopLeftWrapper>
            <S.TopRightWrapper>
              <Button
                type='button'
                onClick={() => {
                  onSearchClick();
                  showInstreamAd(subDomain);
                }}
                data-cy='searchButton'
                variant='tertiaryBlack'
                className='search-button'
                hasRightArrow={false}
              >
                {/* eslint-disable */}
                <label htmlFor='queryly_toggle'></label>
              </Button>
            </S.TopRightWrapper>
          </S.TopWrapper>
        </S.CloseWrapper>
        {children}
        {(!user.isLoggedIn || isSubscribeBtnDisplayed) && pianoScriptFailed === false && (
          <S.BottomWrapper className={`${isRecommendsOrEdu ? 'education' : ''}`}>
            {isMobile && !user.isLoggedIn && (
              <SignInWrapper className={subDomain}>
                <Button
                  className='sign-in-button'
                  dataCy='signinBtnMobile'
                  trackingData={{
                    ...trackingData,
                    eventLabel: 'sign in',
                  }}
                  onClick={showLogin}
                  variant='white'
                  hasRightArrow={false}
                >
                  SIGN IN
                </Button>
              </SignInWrapper>
            )}
            {isMobile && isSubscribeBtnDisplayed && (
              <S.MyAccountWrapper>
                <Link
                  className='subscribe-link'
                  href='/subscribe/'
                  trackingData={subscribeBtnTrackingData}
                  dataCy='subscribeBtnDrawer'
                  type='accentButton'
                  hasRightArrow={false}
                >
                  Subscribe Now
                </Link>
              </S.MyAccountWrapper>
            )}
          </S.BottomWrapper>
        )}
        {isRecommendsOrEdu && <BackToFortune isInDrawerMenu />}
      </S.ContainerDrawer>
      <S.OutsideTheMenu
        className='overlay'
        onClick={handleClick}
      />
    </>
  );
};

export default forwardRef(DrawerMenu);
