import { ForwardedRef, forwardRef, MouseEvent } from 'react';
import { trackEvent } from 'services/Gtm/functions';
import * as S from './buttonStyles';
import { ButtonProps } from './types';

const Button = (
  {
    children,
    className,
    disabled,
    onClick,
    trackingData,
    type = 'button',
    dataCy,
    ariaLabel,
    variant = 'primary',
    hasRightArrow = true,
    hasLeftArrow = false,
    arrowType,
    size,
  }: ButtonProps,
  ref: ForwardedRef<HTMLButtonElement> | undefined,
) => {
  // eslint-disable-next-line consistent-return
  const defaultOnClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (trackEvent && trackingData) trackEvent(trackingData);
    if (onClick) return onClick(event);
  };
  return (
    <S.Button
      className={className}
      type={type}
      disabled={disabled}
      ref={ref}
      data-cy={dataCy}
      $variant={variant}
      $hasRightArrow={hasRightArrow}
      $hasLeftArrow={hasLeftArrow}
      $arrowType={arrowType}
      $size={size}
      {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
      {...(onClick !== undefined ? { onClick: defaultOnClick } : {})}
    >
      {children}
    </S.Button>
  );
};

export default forwardRef(Button);
