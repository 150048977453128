/* eslint-disable no-param-reassign */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { PageAdConfig } from 'interfaces/ads/Ad';
import { ArcSpanPageConfig } from 'services/ArcSpan/entities';
import { UserContext } from 'providers/UserProvider';
import Bombora from 'services/Bombora';
import { AdContext, defaultAdContext } from 'services/Ad';
import ArcSpan from 'services/ArcSpan';
import Script from 'next/script';
import AdManager from './AdManager';

interface AdProviderProps {
  children: JSX.Element;
  pageAdConfig?: PageAdConfig;
  arcSpanPageConfig: ArcSpanPageConfig;
}

/**
 * If no consent is give, do not load ads.
 * Load ArcSpan before the ad library.
 */
const AdProvider: React.FC<AdProviderProps> = ({ children, pageAdConfig, arcSpanPageConfig }) => {
  const { hasConsent } = useContext(UserContext);
  const [adContextValue, setAdContextValue] = useState(defaultAdContext);
  const [renderOutbrainScript, setRenderOutbrainScript] = useState(false);
  const [outbrainScriptLoaded, setOutbrainScriptLoaded] = useState(false);
  const { hasLoggedIn } = useContext(UserContext);
  const renderAdScripts = hasConsent && pageAdConfig;

  useEffect(() => {
    if (hasConsent === false) {
      setAdContextValue(defaultAdContext);
    }
  }, [hasConsent]);

  if (pageAdConfig) {
    pageAdConfig.loginStatus = hasLoggedIn ? 'logged-in' : 'guest';
  }

  const arcSpanConfig = {
    ...pageAdConfig,
    ...arcSpanPageConfig,
  };
  const memoizedAdContextValue = useMemo(
    () => ({ ...adContextValue, outbrainScriptLoaded, setRenderOutbrainScript }),
    [adContextValue, outbrainScriptLoaded],
  );
  return (
    <>
      {renderAdScripts && (
        <>
          <Bombora />
          <AdManager
            pageAdConfig={pageAdConfig}
            setAdContextValue={setAdContextValue}
          />
        </>
      )}
      <AdContext.Provider value={memoizedAdContextValue}>{children}</AdContext.Provider>
      {renderOutbrainScript && (
        <Script
          type='text/javascript'
          async
          strategy='lazyOnload'
          src='//widgets.outbrain.com/outbrain.js'
          onLoad={() => setOutbrainScriptLoaded(true)}
        />
      )}
      {process?.env?.ARCSPAN_ORGANIZATION_ID?.length && (
        <ArcSpan
          pageAdConfig={pageAdConfig}
          arcSpanPageConfig={arcSpanConfig}
        />
      )}
    </>
  );
};

export default AdProvider;
