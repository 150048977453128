import styled, { css } from 'styled-components';
import { breakpoint } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';

export const LeaderboardAdWrapper = styled.div<{ theme: Theme }>`
  background-color: ${getColor('surfaceWhite')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-align: center;
 
  &:empty {
    min-height: 0;
    height: 0;
  }

  &.leaderboard--sticky {
    position: sticky; 
    z-index: 9999;
    top: 0;
    & > div {
      position: sticky;
    }
  }
  @media (${breakpoint.smMin}) {
    min-height: 110px!important;
  } 

  @media (${breakpoint.mdMin}) {
    min-height: 161px!important;
  }

  @media (${breakpoint.lgMin}) {
    min-height: 300px!important;
  }
`;

export const NativoLeaderboard = styled.div`
  min-height: 74px;

  @media (${breakpoint.mdMin}) {
    min-height: 114px;
  }
`;

export const LayoutWrapper = styled.div<{ theme: Theme; $isRecommends: boolean; $showRecommendsFooter: boolean }>`
  // TODO: FECM-1248 refactor this implementation (use one variant for recommends and one general)

  ${({ $isRecommends }) =>
    ($isRecommends ?
      css<{ $showRecommendsFooter: boolean }>`
        overflow: clip;
        // footer on Recommends takes up the whole screen width, margins should only apply to main content
        main {
          margin: auto;
          padding: 0 var(--Grid-Margin);
          
          // we should let the landing page and accolades headers overflow
          &:has(.landing-page-header),
          &:has(.accolades-header) {
            overflow: visible;
          }

          @media (${breakpoint.xxlMin}) {
            max-width: 1440px;
          }

        }
        ${({ $showRecommendsFooter }) =>
        !$showRecommendsFooter &&
          css`
          // we moved margin: auto and the max width value from the layout wrapper element inside the <main> element
          // because of this change we need to adjust the general footer positioning (if it's used on recommends)
          footer {
            margin: var(--Footer-Margin) auto 0;
            max-width: min(100%, ${breakpoint.xxlVal});
          }
        `}
      ` :
      css`
        margin: auto;
        overflow: clip;
        
        @media (${breakpoint.mdMin}) {
          padding: 0px 24px;
        }

        @media (${breakpoint.xlMin}) {
          padding: 0px 48px;
        }
        
        @media (${breakpoint.xxlMin}) {
          max-width: 1440px;
        }
      `)}
`;
