import IterableTracker from 'services/Iterable';
import CookieService from 'utils/miscUtils/CookieService';
import CustomWindow from 'interfaces/Utils';
import { MyFavorites } from 'interfaces/Account';
import { MyAccountDataResponse } from 'lib/personalizationApi/entities/MyAccountDataResponse';
import getMyAccountData from 'lib/personalizationApi/getMyAccountData';
import { AccessResponse } from 'services/Piano/entities/PianoCallbackParams';

declare let window: CustomWindow;

export interface SubscriberContext {
  authorNames: string[];
  email: string;
  hasAccess: boolean | null;
  isLoggedIn: boolean | null;
  iterableTracker: undefined | IterableTracker;
  myFavorites: MyFavorites;
  pianoUid: string;
  subAudience: string;
  topicNames: string[];
  subscribedMessageTypeIds: number[];
  myAccountData: MyAccountDataResponse | null;
  fetchError: boolean;
}

export const defaultSubscriberContext: SubscriberContext = {
  authorNames: [],
  email: '',
  fetchError: false,
  hasAccess: null,
  isLoggedIn: null,
  iterableTracker: undefined,
  myAccountData: null,
  myFavorites: [],
  pianoUid: '',
  subAudience: '',
  subscribedMessageTypeIds: [],
  topicNames: [],
};

export class Subscriber {
  email: string;

  iterableEmail: string | undefined;

  pianoUid: string;

  isLoggedIn: boolean | null;

  hasAccess: boolean | null;

  subAudience: string;

  campaignId: number;

  templateId: number;

  iterableTracker: IterableTracker | undefined;

  topicNames: string[];

  authorNames: string[];

  subscribedMessageTypeIds: number[];

  myFavorites: MyFavorites;

  connect: boolean = false;

  myAccountData: MyAccountDataResponse | null;

  fetchError: boolean = false;

  constructor() {
    this.email = '';
    this.pianoUid = '';
    this.isLoggedIn = false;
    this.hasAccess = false;
    this.subAudience = '';
    this.campaignId = 0;
    this.templateId = 0;
    this.topicNames = [];
    this.authorNames = [];
    this.myFavorites = [];
    this.subscribedMessageTypeIds = [];
    this.myAccountData = null;
    this.fetchError = false;
  }

  public async init() {
    if (window.tp && window.tp.user && window.tp.user.isUserValid()) {
      this.getLoggedIn();
      this.getEmail();
      this.getPianoUid();

      if (this.pianoUid) {
        await this.fetchMyAccountData()
          .then(() => {
            this.fetchError = false;
          })
          .catch(() => {
            this.fetchError = true;
          });
      }
      await this.checkAccess();
    }
    this.checkConnect();
    this.getSubAudience();
  }

  private getLoggedIn(): void {
    this.isLoggedIn = window.tp.user.isUserValid();
  }

  private getEmail(): void {
    this.email = window.tp.pianoId.getUser().email;
  }

  private getPianoUid(): void {
    this.pianoUid = window.tp.pianoId.getUser().uid;
    CookieService.set('pianoUid', this.pianoUid);
  }

  private async checkAccess() {
    const rid: string = process.env.PIANO_RID_DIGITAL || 'default';
    const response: AccessResponse = await new Promise((resolve, reject) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      window.tp.api.callApi('/access/check', { rid }, (data: AccessResponse) => {
        if (data) {
          resolve(data);
        } else {
          reject(new Error('Check access failed'));
        }
      });
    });
    this.hasAccess = response?.access?.granted;
  }

  private getSubAudience(): void {
    const subAudience = CookieService.get('_pc_segment');
    if (subAudience) {
      this.subAudience = subAudience;
    }
  }

  private checkConnect(): void {
    const connectCookie = CookieService.get('_pc_fcsub');
    if (connectCookie === 'true') {
      this.hasAccess = true;
      this.connect = true;
    }
  }

  private async fetchMyAccountData() {
    try {
      this.myAccountData = await getMyAccountData(this.pianoUid);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  public getIterableCampaign(): void {
    this.iterableEmail = CookieService.get('iterableEndUserId');
    const campaign = CookieService.get('iterableEmailCampaignId');
    const template = CookieService.get('iterableTemplateId');
    this.campaignId = campaign ? parseInt(campaign, 10) : 0;
    this.templateId = template ? parseInt(template, 10) : 0;
  }
}

export const validatePianoCookie = (): boolean => CookieService.get('__idr') === '1';
