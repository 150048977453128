import styled from 'styled-components';
import { font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';

export const MenuItemSubUl = styled.ul<{ theme: Theme }>`
  display: none;
  min-width: 236px;
  flex-direction: column;
  position: absolute;
  z-index: 11;
  background-color: ${getColor('neutralWhite')};
  padding: 16px;
  box-shadow: 4px 4px 20px 0px ${getColor('neutralBlack')}0d;
  font-family: ${font.graphikCond};
  top: calc(100% + 16px);
 
  & > li:first-child {
    border-top: 0;
    padding-top: 0;
  }

  &.open {
    display: flex;
    min-width: 230px;
  }

  .submenu-link {
    display: inline-block;

    &:hover {
      color: ${getColor('textSecondary')};
    }
  } 
`;

export const MenuItemSubLi = styled.li`
  width: 100%;
  text-align: left;
  padding-top: 8px;

  .submenu-link {
    font-weight: 600;
    line-height: 150%;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 0.5px;
  }
`;
