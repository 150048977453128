/* eslint-disable jsx-a11y/label-has-associated-control */
import useBreakpoint from 'hooks/useBreakpoint';
import { ForwardedRef, forwardRef } from 'react';
import { useRouter } from 'next/router';
import { Microsites } from 'constants/constants';
import HEADER_NAVIGATION from 'constants/testsIds/headerNavigation';
import MobileNavigationBar from './MobileNavigationBar';
import DesktopNavigationBar from './DesktopNavigationBar';
import * as S from './NavigationBarStyles';

export interface NavigationBarProps {
  domainLogo: string | null;
  subDomain: string;
  logoHref: string;
  id?: string;
}

export const showInstreamAd = (micrositeDomain: string | undefined) => {
  googletag
    .defineSlot(
      `/21809533738/${Microsites.Well === micrositeDomain ? 'fortune.well' : 'fortune'}/search/advancedsearch/instream`,
      [
        [728, 90],
        [300, 250],
        [336, 280],
      ],
      'InstantSearch_InStream0',
    )
    ?.addService(googletag.pubads());
  googletag.pubads().refresh();
  document.getElementById('queryly_query')?.addEventListener('keydown', () => {
    googletag.pubads().refresh();
  });
};

const NavigationBar = (props: NavigationBarProps, ref: ForwardedRef<HTMLLabelElement> | null) => {
  const { domainLogo, subDomain, logoHref, id } = props;
  const isMobile = useBreakpoint('mdMax');
  const isTablet = useBreakpoint('lgMax');
  const router = useRouter();
  const isSubscribePage = router.asPath.includes('/subscribe/');

  return (
    <S.Wrapper
      id={id}
      data-cy={HEADER_NAVIGATION.HEADER_NAV_BAR}
      $isMobile={isMobile}
      $isTablet={isTablet}
    >
      {isMobile ? (
        <MobileNavigationBar
          subDomain={subDomain}
          domainLogo={domainLogo}
          logoHref={logoHref}
          isSubscribePage={isSubscribePage}
          ref={ref}
        />
      ) : (
        <DesktopNavigationBar
          subDomain={subDomain}
          domainLogo={domainLogo}
          logoHref={logoHref}
          isSubscribePage={isSubscribePage}
        />
      )}
    </S.Wrapper>
  );
};

export default forwardRef(NavigationBar);
