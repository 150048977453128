import styled from 'styled-components';
import { Theme } from 'styles/theme';
import { getIcon } from 'styles/icons';

export const PrevArrowWrapper = styled.button<{ theme: Theme }>`
  width: 30px;
  height: 35px;

  &.slick-prev::before {
    display: none;
  }
  &.slick-prev {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ${getIcon('arrow-states')}
`;
export const PrevArrow = styled.span<{ theme: Theme }>`
 ${getIcon('prev-arrow', 'default')};
`;
