import cookies from 'js-cookie';

const CookieService = (() => ({
  get: (name: string) => cookies.get(name),
  remove: (name: string, options?: cookies.CookieAttributes) => {
    const unixEpoch = new Date(0);

    cookies.set(name, '', {
      path: '/',
      ...(options || {}),
      expires: unixEpoch,
    });
  },
  set: (name: string, value: string, options?: cookies.CookieAttributes) => {
    cookies.set(name, value, {
      path: '/',
      ...(options || {}),
    });
  },
}))();

export default CookieService;
