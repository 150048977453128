import styled from 'styled-components';
import { FooterBottomWrapper } from 'components/Footer/FooterStyles';
import { getColor, Theme } from 'styles/theme';
import { font } from 'styles/globals';

export const Wrapper = styled.div`
  display: flex;
  font-family: ${font.graphikCompact};
  flex-direction: column;
  min-height: 100vh;
`;

export const Header = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 0;
  margin-bottom: 40px;
  background: ${getColor('surfaceWhite')};
  box-shadow: 0px 15px 30px 0px ${getColor('surfaceBlack')}10;

  .logo {
    height: 32px;
    cursor: pointer;
  }
`;

export const Footer = styled(FooterBottomWrapper)`
  margin-top: auto;
  margin-bottom: 1px;
`;
