/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { breakpoint } from 'styles/globals';
import { Theme } from 'styles/theme';

export const NativoToutWrapper = styled.div<{ theme: Theme }>`
    min-height: 200px;
    padding: 16px 0;
    
    @media (${breakpoint.mdMax}) {
        min-height: 270px;
    }
    @media (${breakpoint.mmMin}) and (${breakpoint.mdMax}) {
        max-height: 570px;
    }
`;
