import {
  InstreamInhouseAdProps,
  InhouseAdProps,
  StickyInhouseAdProps,
  JsonWidget,
  HtmlWidget,
  HtmlInhouseAdProps,
  InhouseAdDerivedTrackingData,
  TrackableInhouseAd,
} from 'interfaces/ads/Ad';
import toKebabCase from 'lodash.kebabcase';
import toSnakeCase from 'lodash.snakecase';

export function transformImage(image: string) {
  try {
    const parsedImage = JSON.parse(image) as { data: { image_id: string; image_src: string } };
    return {
      id: parsedImage.data?.image_id || '',
      src: parsedImage.data?.image_src || '',
    };
  } catch {
    return { id: '', src: '' };
  }
}

export function transformLink(link: string) {
  try {
    const parsedLink = JSON.parse(link) as { href: string; text: string; blank: string };
    return {
      blank: parsedLink.blank === '1' ? '_blank' : ('_self' as '_blank' | '_self'),
      href: parsedLink.href || '',
      text: parsedLink.text || '',
    };
  } catch {
    return { blank: '_self' as '_blank' | '_self', href: '', text: '' };
  }
}

export function getTrackingData(placement: string, index: number, inhouseAd: InhouseAdProps): TrackableInhouseAd {
  try {
    const trackingData: Partial<InhouseAdDerivedTrackingData> = {};
    trackingData.detailedPlacement = toSnakeCase(placement + index.toString());

    let ctaUrl = null;
    const cta = Array.isArray(inhouseAd.adWidget) ? inhouseAd.adWidget.find((attr) => attr.name === 'cta') : null;
    if (cta) {
      ctaUrl = new URL(transformLink(cta.data).href);
    }

    trackingData.campaign = inhouseAd.itmCampaign || null;
    trackingData.variant = inhouseAd.itmContent || null;
    trackingData.type = inhouseAd?.adType;
    trackingData.targetUrl = ctaUrl ? ctaUrl.href.replace(ctaUrl.search, '') : '';

    return {
      ...inhouseAd,
      tracking: trackingData as InhouseAdDerivedTrackingData,
    };
  } catch {
    return { ...inhouseAd, tracking: {} as InhouseAdDerivedTrackingData };
  }
}

function transformInhouseAd(
  pageType: string,
  placementName: string,
  index: number,
  inhouseAd: InhouseAdProps | null,
): StickyInhouseAdProps | InstreamInhouseAdProps | HtmlInhouseAdProps | null {
  if (inhouseAd === null) {
    return null;
  }
  try {
    const trackableInhouseAd = getTrackingData(placementName, index, inhouseAd);
    if ((pageType === 'article' || pageType === 'article-v2') && placementName === 'Sticky') {
      if (inhouseAd.adType === 'json') {
        const findAttr = (name: string) =>
          (inhouseAd.adWidget as JsonWidget).find((attr) => attr.name === name)?.data || '';

        return {
          ...trackableInhouseAd,
          consent: findAttr('consent'),
          description: findAttr('description'),
          marketingConsent: findAttr('marketing-consent'),
          newsletterId: findAttr('newsletter-id'),
          submitButton: findAttr('submit-button'),
          theme: (findAttr('theme') as 'theme-yellow' | 'theme-blue') || 'theme-yellow',
          title: findAttr('title'),
        };
      }
      throw new Error(`${pageType}${placementName} can't be of type ${inhouseAd.adType}`);
    }

    if (
      (pageType === 'article' || pageType === 'article-v2') &&
      (placementName === 'InStream' || placementName === 'InContent')
    ) {
      if (inhouseAd.adType === 'json') {
        const desktopImage = transformImage(
          (inhouseAd.adWidget as JsonWidget).find((attr) => attr.name === 'desktop-image')?.data || '{}',
        );
        const mobileImage = transformImage(
          (inhouseAd.adWidget as JsonWidget).find((attr) => attr.name === 'mobile-image')?.data || '{}',
        );

        const link = transformLink(
          (inhouseAd.adWidget as JsonWidget).find((attr) => attr.name === 'cta')?.data || '{}',
        );

        return {
          ...trackableInhouseAd,
          cta: link,
          desktopImage,
          mobileImage,
        };
      }
      throw new Error(`${pageType}${placementName} can't be of type ${inhouseAd.adType}`);
    }

    if (inhouseAd.adType === 'html') {
      return {
        ...trackableInhouseAd,
        css: (inhouseAd.adWidget as HtmlWidget)?.css || '',
        html: (inhouseAd.adWidget as HtmlWidget)?.html || '',
      };
    }

    return null;
  } catch (e) {
    return null;
  }
}

const TRACKING_PROPS_PREFIX = 'data-tracking';

interface HtmlTrackingPropsMap {
  [key: string]: string | null;
}

// Generate the same tracking props as in the function above, but for HTML
// The props get prefixd wth TRACKING_PROPS_PREFIX and are hyphenated
function getGtmTrackingHtmlProps(pageType: string, placementName: string, inhouseAd: TrackableInhouseAd) {
  const trackingProps: HtmlTrackingPropsMap = {
    adId: inhouseAd.adId,
    placementId: `${pageType}-${placementName}`,
    ...inhouseAd.tracking,
  };

  const htmlTrackingProps: HtmlTrackingPropsMap = {};

  Object.keys(trackingProps).forEach((currentProp: string) => {
    htmlTrackingProps[`${TRACKING_PROPS_PREFIX}-${toKebabCase(currentProp)}`] = trackingProps[currentProp];
  });

  return htmlTrackingProps;
}

export { transformInhouseAd, getGtmTrackingHtmlProps };
