import { useRef } from 'react';

let globalCounter = 0;

function useOutbrainIndex() {
  const indexRef = useRef<number | null>(null);
  if (indexRef.current === null) {
    indexRef.current = globalCounter;
    globalCounter += 1;
  }

  return indexRef.current;
}

export default useOutbrainIndex;
