import CookieService from 'utils/miscUtils/CookieService';

const findOnetrustCookieOptIn = () => {
  const OTCookieName = 'OptanonConsent';
  const oneTrust = CookieService.get(OTCookieName);
  if (oneTrust) {
    if (oneTrust && oneTrust.includes('C0004:1')) {
      return true;
    }
  }
  return false;
};

export default findOnetrustCookieOptIn;
