import { MenuItemProps } from 'interfaces/navigation/Header';
import Link from 'components/Globals/Base/Link';
import { useMemo } from 'react';
import { depthOf } from 'utils/objectUtils';
import DRAWER_MENU_TEST_IDS from 'constants/testsIds/drawerMenu';
import * as S from './MenuItemStyles';

interface ExtendedMenuItemProps extends MenuItemProps {
  currentUrl: string;
  index?: number;
  activeIndex?: number;
  isSubmenuActive: boolean;
  isArrow: () => void;
  onChange?: (className: string, index: number) => void;
}

const MenuItem = (props: ExtendedMenuItemProps) => {
  const {
    label,
    subMenu,
    url,
    nestingLevel,
    currentUrl,
    index,
    activeIndex,
    onKeyDown,
    onChange,
    isSubmenuActive,
    isArrow,
  } = props;

  const maxNestingLevel = useMemo<number>(() => {
    if (!nestingLevel) {
      const depth: number = depthOf<MenuItemProps>(props, 'subMenu');
      return depth;
    }
    // eslint-disable-next-line react/destructuring-assignment
    return props.maxNestingLevel as number;
  }, [nestingLevel, props]);

  const trackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: `Global Side navigation - level ${nestingLevel || '0'}`,
    eventCategory: 'navigation',
  };

  const openCloseMenu = () => {
    isArrow();
    if (onChange) onChange(isSubmenuActive ? 'not-open' : 'open', index || -1);
  };

  const subMenuHead = (
    <S.Item
      className='submenu-head'
      $nestingLevel={0}
      $subMenu={false}
    >
      <S.Icon
        className='arrow right'
        onClick={openCloseMenu}
        type='button'
        data-cy={DRAWER_MENU_TEST_IDS.ARROW}
      >
        <span className='arrow' />
      </S.Icon>
      <Link
        href={url}
        className='menu-link'
        trackingData={{ ...trackingData, eventLabel: label }}
        onKeyDown={onKeyDown}
      >
        {label}
      </Link>
    </S.Item>
  );

  const menuItem = (
    <S.Item
      className={`level-${nestingLevel || 0} ${activeIndex === index ? 'active' : ''}`}
      $nestingLevel={nestingLevel || 0}
      $subMenu={nestingLevel !== maxNestingLevel}
      data-cy={`itemLink${nestingLevel || 0}`}
      id={`tab-${index}`}
    >
      <Link
        href={url}
        className='menu-link'
        trackingData={{ ...trackingData, eventLabel: label }}
        onKeyDown={onKeyDown}
      >
        {label}
      </Link>
      {subMenu && !isSubmenuActive ? (
        <S.Icon
          className='arrow'
          onClick={openCloseMenu}
          type='button'
          data-cy={DRAWER_MENU_TEST_IDS.ARROW}
        >
          <span className='arrow' />
        </S.Icon>
      ) : null}
    </S.Item>
  );

  return subMenu ? (
    <>
      {menuItem}
      <S.Submenu
        className='submenu'
        data-cy={`subMenuLevel${nestingLevel || 0}`}
      >
        {subMenuHead}
        {subMenu.map((submenuItem) => (
          <MenuItem
            key={submenuItem.id}
            id={submenuItem.id}
            label={submenuItem.label}
            url={submenuItem.url}
            subMenu={submenuItem.subMenu}
            currentUrl={currentUrl}
            nestingLevel={(nestingLevel || 0) + 1}
            maxNestingLevel={maxNestingLevel}
            isSubmenuActive={isSubmenuActive}
            isArrow={isArrow}
          />
        ))}
      </S.Submenu>
    </>
  ) : (
    menuItem
  );
};

export default MenuItem;
