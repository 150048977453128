import { useState } from 'react';
import Link from 'components/Globals/Base/Link';
import { UserCircle } from 'icons/UserCircle';
import logOut from 'services/Piano/logOut';
import { MyAccountLinks } from 'constants/constants';
import useBreakpoint from 'hooks/useBreakpoint';
import HEADER_NAVIGATION from 'constants/testsIds/headerNavigation';
import * as S from './MyAccountStyles';

export interface MyAccountProps {
  className?: string;
}

const MyAccount = ({ className }: MyAccountProps) => {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const isMobile = useBreakpoint('mdMax');

  return (
    <S.MyAccountWrapper
      className={`${className} ${isSubMenuOpen ? 'open' : ''}`}
      data-cy={HEADER_NAVIGATION.MY_ACCOUNT_DROPDOWN}
    >
      {isMobile ? (
        <S.UserButton
          type='button'
          onClick={() => setIsSubMenuOpen(!isSubMenuOpen)}
          data-cy='userIconBtn'
        >
          <UserCircle />
        </S.UserButton>
      ) : (
        <S.AccordionWrapper className={isSubMenuOpen ? 'open' : ''}>
          <S.MyAccountLink
            href='/my-account/'
            className='my-account-link'
          >
              MY ACCOUNT
          </S.MyAccountLink>
          <S.Arrow className='arrow' />
        </S.AccordionWrapper>
      )}
      <S.SubMenu className='submenu'>
        {MyAccountLinks.map((item) => (
          <S.SubMenuItem key={item.url}>
            <Link
              href={item.url}
              aria-label={`Navigate to ${item.label} section of My Account`}
              type='primary'
              hasRightArrow={false}
              data-cy={`my-account-${item.label.toLowerCase().replace(' ', '-')}-navlink`}
            >
              {item.label}
            </Link>
          </S.SubMenuItem>
        ))}
        <S.SubMenuItem>
          <button
            type='button'
            onClick={logOut}
            data-cy='logoutBtnDesktop'
          >
              SIGN OUT
          </button>
        </S.SubMenuItem>
      </S.SubMenu>
    </S.MyAccountWrapper>
  );
};

export default MyAccount;
