import styled from 'styled-components';
import { Theme, getBodyStyle } from 'styles/theme';
import { breakpoint, font } from 'styles/globals';

export const Description = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCompact', { default: 'medium', mdMin: 'default' }, 'regular')};
  padding: 24px 0 40px 0;
`;

export const Instructions = styled.div`
  font-size: 16px;
  padding-bottom: 40px;

  ul {
    padding-top: 8px;
  }

  li {
    height: 24px;
    padding: 2px 0 0 32px;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    margin-bottom: 16px;
  }

  em {
    font-style: normal;
    font-weight: bold; 
  }

  .refresh {
    background-image: url('/icons/svg/refresh.svg');
  }

  .homepage {
    background-image: url('/icons/svg/clock.svg');
  }

  .email {
    background-image: url('/icons/svg/email.svg');
  }
`;

export const ContentWrapper = styled.div`
  font-family: ${font.graphikCompact};
  padding: 40px 20px 40px;
  flex: 1;

  .full-width-button {
    width: 100%;
  }

  @media (${breakpoint.mdMin}) {
    padding: 48px 32px 40px;

    .full-width-button {
      width: unset;
    }
  }

  @media (${breakpoint.lgMin}) {
    display: flex;
    align-items: center;
    gap: 24px;
  }

  @media (${breakpoint.xlMin}) {
    gap: 40px;
    justify-content: space-around;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  order: 2;
`;

export const ErrorImage = styled.span`
  display: block;
  width: 320px;
  height: 110px;
  background-repeat: no-repeat;
  background-size: 320px 110px;
  background-image: url('/icons/svg/error500.svg');
  margin-bottom: 40px;

  @media (${breakpoint.mdMin}) {
    width: 468px;
    height: 162px;
    background-size: 468px 162px; 
  }

  @media (${breakpoint.xlMin}) {
    width: 545px;
    height: 188px;
    background-size: 545px 188px; 
  }
`;
