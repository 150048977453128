/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */

export interface BarsProps {
  isMobile?: boolean;
}

export const Bars = ({ isMobile = true }: BarsProps) => {

  if (isMobile) {
    return (
      <svg width='24' height='17' viewBox='0 0 24 17' fill='#111111' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 16.3784V13.7118H24V16.3784H0ZM0 9.71175V7.04508H24V9.71175H0ZM0 3.04508V0.378418H24V3.04508H0Z' />
      </svg>
    );
  } 
  return (
    <svg width='30' height='25' viewBox='0 0 30 25' fill='#111111' xmlns='http://www.w3.org/2000/svg'>
      <rect y='0.378418' width='30' height='4' />
      <rect y='10.3784' width='30' height='4' />
      <rect y='20.3784' width='30' height='4' />
    </svg>
  );
  
};
