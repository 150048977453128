import styled, { css } from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { Theme, getColor } from 'styles/theme';
import { getIcon } from 'styles/icons';

interface DrawerMenuProps {
  $isSubscribeBtn: boolean;
  theme: Theme;
}

interface ContainerDrawerProps {
  $isSubscribeBtn: boolean;
  $isLogIn: boolean;
  theme: Theme;
}

export const ContainerDrawer = styled.div<ContainerDrawerProps>`
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: ${getColor('surfaceWhite')};
  transition: all 0.3s ease-in-out;
  z-index: 100002;
  padding: 0 16px;
  visibility: hidden;

  a:hover {
    color: ${getColor('textSecondary')}
  }

  .close-menu {
    &:hover {
        &:before {
        background-color: ${getColor('textSecondary')}
      }
    }
  }
  .links-menu {
    margin: 0 20px;
    overflow: hidden auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none; // Safari and Chrome
    }
    
    height: calc(100vh - 195px);
    ${({ $isLogIn, $isSubscribeBtn }) =>
    $isLogIn &&
      $isSubscribeBtn &&
      css`
        height: calc(100vh - 165px);
    `}
    ${({ $isLogIn, $isSubscribeBtn }) =>
    $isLogIn &&
      !$isSubscribeBtn &&
      css`
        height: calc(100vh - 73px);
    `}
  }
  
  &.open {
    left: 0;
    visibility: visible;
  }

  @media (${breakpoint.mdMin}) {
    width: 100%;
    left: -100%;
    padding: 0 24px 24px;
  }

  @media (${breakpoint.lgMin}) {
    max-width: 375px;
    left: -375px;
  }

  .extra-padding {
    padding-bottom: 64px;
  }

  @supports (height: 100svh) {
    height: 100%;

    .extra-padding {
      padding-bottom: 0;
    }
  }
`;

export const CloseWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  position: sticky;
  background-color: ${getColor('surfaceWhite')};
  top: 0;
  left: 20px;
  padding-top: 16px;
  padding-bottom: 8px;
  z-index: 9999;
  justify-content: space-between;
  border-bottom: 1px solid ${getColor('borderSecondary')};
  min-height: 70px;

  @media (${breakpoint.mdMin}) {
    padding-top: 24px;
  }
`;

export const BottomWrapper = styled.div<{ theme: Theme }>`
  @media (${breakpoint.mdMax}) {
    border-top: 1px solid ${getColor('borderPrimary')};
    background-color: ${getColor('neutralWhite')};
    position: sticky;
    bottom: 16px;
    padding-top: 5px;
    z-index:9999;

    &.education {
      bottom: 60px;
    }
    .subscribe-link {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
    }
  }
`;

export const MyAccountWrapper = styled.div<{ theme: Theme }>`
  & .subscribe-link {
    display: flex;

    @media (${breakpoint.xlMin}) {
      font-size: 16px;
    }
  }
`;

export const TopLeftWrapper = styled.div`
  display: inline-flex;
  .close-menu {
    margin-right: 24px;
  }
`;

export const TopRightWrapper = styled.div`
  display: inline-flex;
`;

export const TopWrapper = styled.div<DrawerMenuProps>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .link {
    font-family: ${font.graphikCond};
    font-weight: 500;
    font-size: 16px;
    margin-left: 32px;
    display: flex;
    text-transform: uppercase;
  }

  .search-button {
    cursor: pointer;
    &:hover {
      label {
        &::before {
          background-color: ${getColor('textSecondary')};
        }
      }
    }

    label {
      cursor: pointer;
      &::before {
        ${getIcon('search-icon', { height: 24, width: 24 })};
        background-color: ${getColor('iconPrimary')};
      }

     
    }
  }

  .mobile-logo {
    .logo {
      height: 24px;
      cursor: pointer;
    }

    .crypto-logo {
      height: 24px;
      width: 170px;
    }

    .well-logo {
      height: 24px;
      width: 178px;
      cursor: pointer;
    }

    .recommends-logo {
      height: 16px;
      width: 200px;
      cursor: pointer;
    }
      
    .education-logo {
      height: 16px;
      cursor: pointer;
    }

    @media (${breakpoint.mdMin}) {
      display: flex;
      align-items: center;
    }

  }

  @media (${breakpoint.mdMin}) {
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
`;

export const OutsideTheMenu = styled.div<{ theme: Theme }>`
  z-index: 100001;
  position: fixed;
  inset: 0;
  background-color: ${getColor('surfaceBlack')};
  opacity: 0;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
`;
