import { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react';
import { useRouter } from 'next/router';
import DrawerMenu from 'components/DrawerMenu';
import Menu from 'components/Header/Menu';
import NavigationBar from 'components/NavigationBar';
import TopicNavigation from 'components/TopicBasedNavigation';
import { MenuItemProps } from 'interfaces/navigation/Header';
import { Microsites, topicNavBorderColor, defaultDisclosure } from 'constants/constants';
import BinanceTickerBar from 'components/BinanceTickerBar';
import TradingViewTicker from 'components/TradingViewTicker';
import ExpiredSubAlert from 'components/ExpiredSubAlert';
import Notice from 'components/Notice';
import { NoticeContext } from 'providers/NoticeProvider';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
import _debounce from 'lodash/debounce';
import { Disclosure } from 'api/MicrositeConfig/getMicrositeConfig';
import DisclosureStripe from 'components/DisclosureStripe';
import * as S from './HeaderStyles';

export interface HeaderProps {
  subDomain: string;
  domainLogo: string | null;
  topics: MenuItemProps[];
  disclosure?: Disclosure;
  templateType?: string;
  leftMenu: MenuItemProps[];
  rightMenu: MenuItemProps[];
  currentTags?: string[];
}

const Header = ({
  subDomain,
  domainLogo,
  topics,
  leftMenu,
  rightMenu,
  disclosure,
  templateType,
  currentTags,
}: HeaderProps) => {
  const { asPath } = useRouter();
  const showTradingViewWidget = asPath === '/section/finance/';
  const hamburgerButtonRef = useRef<HTMLLabelElement>(null);
  const menuToggleRef = useRef<HTMLInputElement>(null);
  const closeButtonRef = useRef<HTMLDivElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { notice, noticeVisible } = useContext(NoticeContext);

  let disclosureProps;

  if (disclosure) {
    disclosureProps = { content: disclosure, subDomain, templateType };
  } else if (currentTags?.includes('ShowDisclaimer')) {
    disclosureProps = { content: defaultDisclosure, subDomain, templateType };
  } else {
    disclosureProps = undefined;
  }

  const isRecommendsOrEdu =
    !process.env.FEATURE_FLAG_DISABLE_RECOMMENDS && [Microsites.Recommends, Microsites.Education].includes(subDomain);

  const logoHref = useMemo(() => {
    if (isRecommendsOrEdu) return `/${Microsites.Recommends}/`;

    if (['own', Microsites.Europe, Microsites.Asia, Microsites.Recommends, Microsites.Education].includes(subDomain))
      return '/';
    return `/${subDomain}/`;
  }, [subDomain, isRecommendsOrEdu]);

  const handleMenuToggle = () => {
    if (menuToggleRef.current) {
      const newState = !menuToggleRef.current.checked;
      menuToggleRef.current.checked = newState;
      setIsDrawerOpen(newState);
      if (newState) {
        setTimeout(() => {
          closeButtonRef.current?.focus();
        }, 200);
      } else {
        setTimeout(() => {
          hamburgerButtonRef.current?.focus();
        }, 200);
      }
    }
  };

  const isDrawerMenu = (e: KeyboardEvent) => {
    const currentID = (e.target as HTMLElement).id || '';
    return currentID === 'drawer-toggle-button';
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (!isDrawerMenu(e)) return;
    if ((e.key === 'Enter' || e.key === ' ') && hamburgerButtonRef.current) {
      e.preventDefault();
      handleMenuToggle();
    } else if (e.key === 'Escape' && menuToggleRef.current) {
      menuToggleRef.current.checked = false;
      setIsDrawerOpen(false);
      setTimeout(() => {
        hamburgerButtonRef.current?.focus();
      }, 500);
    }
  };

  const handleClickOutside = useCallback((e: MouseEvent) => {
    if (closeButtonRef.current && !closeButtonRef.current.contains(e.target as Node)) {
      if (menuToggleRef.current) {
        menuToggleRef.current.checked = false;
        setIsDrawerOpen(false);
      }
    }
  }, []);

  const trapFocus = useCallback(
    (e: FocusEvent) => {
      if (isDrawerOpen && closeButtonRef.current && !closeButtonRef.current.contains(e.target as Node)) {
        e.preventDefault();
        e.stopPropagation();

        const focusableElements = closeButtonRef.current.querySelectorAll('a, [tabindex]:not([tabindex="-1"])');
        const firstFocusableElement = focusableElements[0] as HTMLElement;
        const lastFocusableElement = focusableElements[focusableElements.length - 1] as HTMLElement;

        if (e.target === firstFocusableElement) {
          lastFocusableElement.focus();
        } else {
          firstFocusableElement.focus();
        }
      }
    },
    [isDrawerOpen],
  );

  const debounceKeyDown = useCallback(_debounce(handleKeyDown, 500), []);

  useEffect(() => {
    document.addEventListener('keydown', debounceKeyDown);
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('focusin', trapFocus);

    return () => {
      document.removeEventListener('keydown', debounceKeyDown);
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('focusin', trapFocus);
    };
  }, [handleKeyDown, handleClickOutside, trapFocus]);

  useLockBodyScroll(isDrawerOpen);

  return (
    <>
      <S.DrawerToggle
        type='checkbox'
        id='drawer-toggle'
        className='drawer-toggle'
        ref={menuToggleRef}
        aria-label='Toggle Menu'
        onChange={(e) => setIsDrawerOpen(e.target.checked)}
      />
      <NavigationBar
        id='header-wrapper-id'
        subDomain={subDomain}
        domainLogo={domainLogo}
        logoHref={logoHref}
        ref={hamburgerButtonRef}
      />
      {topics.length > 0 && (
        <TopicNavigation
          subdomain={subDomain}
          color={topicNavBorderColor[subDomain]}
          className={`${disclosureProps ? 'no-margin' : ''}`}
          topics={topics}
        />
      )}
      {disclosureProps &&
        (currentTags?.includes('ShowDisclaimer') ? (
          <S.DisclaimerArticleWrapper id='disclaimer-wrapper-id'>
            <DisclosureStripe
              {...disclosureProps}
              className='sticky-disclosure'
            />
          </S.DisclaimerArticleWrapper>
        ) : (
          <S.DisclaimerWrapper id='disclaimer-wrapper-id'>
            <DisclosureStripe
              {...disclosureProps}
              className='sticky-disclosure'
            />
          </S.DisclaimerWrapper>
        ))}
      {showTradingViewWidget && <TradingViewTicker />}
      {noticeVisible ? (
        <Notice
          type={notice?.type}
          message={notice?.message}
          visible={noticeVisible}
        />
      ) : null}
      {Microsites.Crypto === subDomain && <BinanceTickerBar />}
      <ExpiredSubAlert />

      <DrawerMenu
        subDomain={subDomain}
        ref={closeButtonRef}
        handleClick={() => setIsDrawerOpen(menuToggleRef.current?.checked ?? false)}
        keyboardToggle={handleMenuToggle}
        onSearchClick={handleMenuToggle}
      >
        <Menu
          currentUrl={asPath}
          leftMenu={leftMenu}
          rightMenu={rightMenu}
          subDomain={subDomain}
          className='links-menu'
          onSearchClick={handleMenuToggle}
          onKeyDown={handleMenuToggle}
          isDrawerOpen={isDrawerOpen}
        />
      </DrawerMenu>
    </>
  );
};

export default Header;
