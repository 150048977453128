import styled from 'styled-components';

const OutbrainStyles = styled.div`
    padding: 16px 0;
    &.footer {
        margin-top: 48px;
        padding: 0;
    }

    &.hidden {
        height: 1px;
        width: 1px;
        position: absolute;
    }
`;

export default OutbrainStyles;
