import Cookies from 'js-cookie';
import CookieService from './CookieService';

interface OneTrustCookie {
  name: string;
  defaultValue?: string; // Whether to revert to a default value when clearing
}

// OneTrust cookies as per the official docs
// ⚠️ Before publishing a new OneTrust version, please ensure all of the cookies specified in the documentation
// ⚠️ are present in this list and their default values to reset to (if any) are properly configured
// https://my.onetrust.com/s/article/UUID-2dc719a8-4be5-8d16-1dc8-c7b4147b88e0?language=en_US
const oneTrustCookieJar: OneTrustCookie[] = [
  {
    name: 'OptanonConsent',
  },
  {
    defaultValue: 'Invalid Date',
    name: 'OptanonAlertBoxClosed',
  },
  {
    name: 'euconsent-v2',
  },
  {
    name: 'eupubconsent-v2',
  },
  {
    name: 'OTAdditionalConsentString',
  },
  {
    name: 'OneTrustWPCCPAGoogleOptOut',
  },
  {
    name: 'OTGPPConsent',
  },
];

export default function clearConsentCookies() {
  // OneTrust sets the Production mode cookies to include any subdomain (.example.com)
  // The Test version of the OneTrust script does *NOT* set the domain explicitly
  // In order to ensure we don't create / manipulate duplicated cookies, we have to specify the domain in the same way OneTrust does
  // That is, the domain gets specified only on Production, and it includes all subdomains (has a leading period mark)
  // https://my.onetrust.com/s/article/UUID-2dc719a8-4be5-8d16-1dc8-c7b4147b88e0?language=en_US#:~:text=When%20production%20scripts,domain%20(.onetrust.com).
  const attributes: Cookies.CookieAttributes =
    process.env.ENVIRONMENT === 'prod' ?
      {
        domain: `.${window.location.hostname}`,
      } :
      {};

  oneTrustCookieJar.forEach((cookie) => {
    if (cookie.defaultValue) {
      CookieService.set(cookie.name, cookie.defaultValue, attributes);
    } else {
      CookieService.remove(cookie.name, attributes);
    }
  });
}
