import { DrawerToggleButton } from 'components/Header/HeaderStyles';
import Link from 'components/Globals/Base/Link';
import { Bars } from 'icons/Bars';
import { Logo } from 'icons/Logo';
import { UserContext } from 'providers/UserProvider';
import { ForwardedRef, forwardRef, useContext } from 'react';
import { trackEvent } from 'services/Gtm/functions';
import Placeholder from 'components/Placeholder';
import MyAccount from 'components/MyAccount';
import * as S from './MobileNavigationBarStyles';

export interface MobileNavigationBarProps {
  domainLogo: string | null;
  subDomain: string;
  logoHref: string;
  isSubscribePage: boolean;
}

// eslint-disable-next-line react/no-danger, max-len
const renderDomainLogo = (html?: string | null) => html && <div dangerouslySetInnerHTML={{ __html: html }} />;

const MobileNavigationBar = (props: MobileNavigationBarProps, ref: ForwardedRef<HTMLLabelElement> | null) => {
  const { domainLogo, subDomain, logoHref, isSubscribePage } = props;
  const { userState: user, pianoScriptFailed } = useContext(UserContext);
  const displayLogo = ['crypto', 'well'].includes(subDomain);

  // const isSubscribeBtnDisplayed = Microsites.Well !== subDomain && !user.hasAccess;

  // const subscribeBtnTrackingData = {
  //   contentPlacementCD: 'mobile_subscribe_cta_top_right',
  //   eventAction: 'cta',
  //   eventCategory: 'navigation',
  //   loggedIn: user?.isLoggedIn,
  // };

  const burgerTrackingData = {
    contentPlacementCD: 'Global Side navigation',
    eventAction: 'Global Side navigation toggle',
    eventCategory: 'navigation',
    eventLabel: 'open',
  };

  const trackingData = {
    contentPlacementCD: 'header',
    eventAction: 'top header links',
    eventCategory: 'navigation',
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      e.currentTarget.click();
    }
  };

  return (
    <S.NavigationMobileContent key='mobile'>
      <S.LeftSide>
        <DrawerToggleButton
          htmlFor='drawer-toggle'
          className='hamburger-toggle'
          onClick={() => {
            if (trackEvent && burgerTrackingData) trackEvent(burgerTrackingData);
          }}
          onKeyDown={handleKeyDown}
          aria-label='Open menu'
          data-cy='burger-button'
          ref={ref}
          tabIndex={0}
        >
          <Bars isMobile />
        </DrawerToggleButton>
        <Link
          href={logoHref}
          ariaLabel='Homepage'
          dataCy='logo'
          className='logo'
        >
          {(displayLogo && renderDomainLogo(domainLogo)) || <Logo />}
        </Link>
      </S.LeftSide>
      <S.RightSide>
        {user.isLoggedIn === null && user.hasAccess === null && pianoScriptFailed === false && (
          <Placeholder
            size={{ height: 40, width: 100 }}
            className='account-placeholder'
          />
        )}

        {user.isLoggedIn === false && !isSubscribePage && (
          <Link
            href='/subscribe/'
            trackingData={{
              ...trackingData,
              eventLabel: 'subscribe now',
            }}
            dataCy='subscribeBtnMobile'
            type='accentButton'
            size={{ default: 'xsmall' }}
            hasRightArrow={false}
            className='subscribe-cta-mobile'
          >
            SUBSCRIBE
          </Link>
        )}

        {user.isLoggedIn && <MyAccount />}
      </S.RightSide>
    </S.NavigationMobileContent>
  );
};

export default forwardRef(MobileNavigationBar);
