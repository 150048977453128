import Heading from 'styles/typography/Heading';
import CustomLink from 'components/Globals/Base/Link';
import * as S from './Error500Styles';

const Error500 = () => (
  <S.ContentWrapper>
    <S.ImageWrapper>
      <S.ErrorImage />
    </S.ImageWrapper>

    <div>
      <Heading
        as='h2'
        $fontFamily='saol'
        $size={{ default: 'level2' }}
      >
        Our systems need a quick pivot.
      </Heading>
      <S.Description>We’re addressing the issue and hope to have it fixed soon.</S.Description>

      <S.Instructions>
        <span>Here’s what you can do in the meantime: </span>
        <ul>
          <li className='refresh'>Refresh this page</li>
          <li className='homepage'>
            <em>Visit our homepage</em> &nbsp;to explore our latest stories.
          </li>
          <li className='email'>
            <em>
              Reach out to us at{' '}
              <CustomLink
                href='mailto:support@fortune.com'
                type='bluePrimary'
              >
                support@fortune.com
              </CustomLink>
            </em>{' '}
            &nbsp;if you need assistance or have questions.
          </li>
        </ul>
      </S.Instructions>

      <CustomLink
        href='/'
        type='primaryButton'
        className='full-width-button'
      >
        Go to Homepage
      </CustomLink>
    </div>
  </S.ContentWrapper>
);

export default Error500;
