import { CompanyRankingList } from 'api/fragments';

export function omitKeys<T extends { [key: string]: any }>(obj: T, keys: string[]): T {
  const newObj = { ...obj };
  keys.forEach((key) => {
    delete newObj[key];
  });
  return newObj;
}

export function omitKeysArray<T extends { [key: string]: any }>(obj: T[], keys: string[]): T[] {
  return obj.map((el) => omitKeys(el, keys));
}

export function depthOf<T>(object: T, accessor: string, level = 0): number {
  // @ts-ignore
  const children = object[accessor] as T[] | undefined;
  let newLevel = level;
  if (children) {
    children.forEach((child) => {
      newLevel = Math.max(newLevel, depthOf(child, accessor, level + 1));
    });
  }
  return newLevel;
}

export const showRankValue = (ranking: CompanyRankingList) => {
  let showRank = true;

  if (ranking.hideRanking === null || ranking.hideRanking || (!ranking.hideRanking && ranking.rank === null))
    showRank = false;

  return showRank;
};

/**
 * Picks specific fields from an object and returns a new object containing only those fields.
 *
 * @template T - The type of the input object
 * @param {T} obj - The source object to pick fields from
 * @param {(keyof T)[]} keys - An array of keys to extract from the object
 * @returns {Partial<T>} - A new object containing only the specified fields if they exist in the source object
 */
export function filterObjectKeys<T extends object>(obj: T, keys: (keyof T)[]): Partial<T> {
  const result: Partial<T> = {};
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < keys.length; i++) {
    const key = keys[i];
    if (key in obj) {
      result[key] = obj[key];
    }
  }
  return result;
}
