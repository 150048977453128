import styled from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getIcon } from 'styles/icons';
import { getColor, Theme } from 'styles/theme';

export const NoticeWrapper = styled.div<{ theme: Theme }>`
  position: fixed;
  left: 0;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  width: 100%;
  justify-content: space-between;
  z-index: 4;

  @media (${breakpoint.mdMax}) {
    padding: 5px 12px;
    align-items: start;
  }

  &.hidden {
    display: none;
  }


  &.success {
    .icon {
      fill: ${getColor('semanticSuccess')};
    }
    border: 1px solid ${getColor('semanticSuccess')};
    background: ${getColor('shadeSemanticSuccess100')};
  }
  &.warning {
    .icon {
      path {
        fill: ${getColor('semanticsWarning')};
      }
    }
    border: 1px solid ${getColor('semanticsWarning')};
    background: ${getColor('shadeSemanticWarning100')};
  }
  &.error {
    .icon {
      path {
        fill: ${getColor('semanticsError')} !important;
      }
    }
    border: 1px solid ${getColor('semanticsError')};
    background: ${getColor('shadeSemanticError100')};
  }
`;

export const CloseWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  ${getIcon('svg-states')};
  width: 14px;
  height: 24px;

  &::before {
    ${getIcon('close-icon', { height: 13, width: 12 })};
    background-color: ${getColor('neutralBlack')};
    margin: auto;
  }

  @media (${breakpoint.lgMin}) and (${breakpoint.xxlMax}) {
    right: 20px;
  }
`;

export const MessageWrapper = styled.div<{ theme: Theme }>`
  display: flex;
  gap: 10px;
  position: relative;
  line-height: 24px;
  align-items: center;
  margin: 0 auto;
  width: 100%;

  @media (${breakpoint.mdMax}) {
    align-items: start;
    padding-left: 0;
  }

  @media (${breakpoint.xxlMin}) {
    max-width: 1345px;
  } 

  @media (${breakpoint.lgMin}) and (${breakpoint.xxlMax}) {
    padding: 0 24px;
  }
  
  .icon {
    width: 16px;
    height: 24px;
  }

  p {
    font-family: ${font.graphikCond};
    color: ${getColor('textPrimary')};
    font-size: 16px;
    letter-spacing: 0.5px;
    padding-right: 16px;
  }
`;

export default NoticeWrapper;
