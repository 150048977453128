import CustomLink from 'components/Globals/Base/Link';
import styled, { css } from 'styled-components';
import { breakpoint, font } from 'styles/globals';
import { getColor, Theme } from 'styles/theme';
import { arrowDown, arrowUp, getIcon } from 'styles/icons';

const arrow = css<{ theme: Theme }>`
  padding: 5px;
  border-width: 0 2px 2px 0;
`;

export const Arrow = styled.span<{ theme: Theme }>`
  ${arrowDown}
  ${arrow}
  margin-bottom: 4.5px;
`;

export const UserButton = styled.button<{ theme: Theme }>`
  svg {
    width: 24px;
    height: 24px;
  }
  margin-left: 24px;
  &:hover {
    .arrow {
      border-color: ${getColor('iconAccentPrimary')};
    }
  }

  &:focus {
    .arrow {
      border-color: ${getColor('iconSecondary')};
    }
  }
`;

export const MyAccountWrapper = styled.div<{ theme: Theme }>`
  position: relative;
  width: fit-content;
  @media (${breakpoint.mdMax}) {
    .submenu {
      li {
        padding-bottom: 16px;
      }
      width: 180px;
      li:first-child {
        border-bottom: none;
        padding-bottom: 16px;
      }
      li:last-child {
        margin-top: 0;
        padding-bottom: 0;
      }
    }
    &.open {
      .submenu {
        display: block;
      }
    }
  }

  @media (${breakpoint.mdMin}) {
    display: block;
    margin-left: 24px;
    &:hover {
      .submenu {
        display: block;
      }
    }
  }


  @media (${breakpoint.mdMin}) {
    &:hover {
      .my-account-link {
        color: ${getColor('textSecondary')};
      }
      .arrow {
        ${arrowUp}
        ${arrow}
        border-color: ${getColor('textSecondary')};
      }
    }
  }
`;

export const AccordionWrapper = styled.div<{ theme: Theme }>`
  min-height: 57px;
  display: flex;
  align-items: center;
  
  &.open {
    .arrow {
      transform: rotate(-90deg) translateX(-2px);
      border-color: ${getColor('iconAccentPrimary')};
    }
    .my-account-link {
      color: ${getColor('textLink')};
    }
    @media (${breakpoint.lgMax}) {
      +ul {
        display: flex;
        flex-direction: column;
      }
    }
  }
`;

export const MyAccountLink = styled(CustomLink)<{ theme: Theme }>`
  margin-right: 8px;
  color: ${getColor('textPrimary')};
  font-family: ${font.graphikCond};
  font-weight: 600;
  font-size: 20px;
  line-height: 18px;

  @media (${breakpoint.mdMax}) {
    display: flex;
    justify-content: flex-end;

    &::before {
      ${getIcon('my-account', { height: 32, width: 32 })};
      background-color: ${getColor('iconPrimary')};
    }
  }
`;

export const SubMenu = styled.ul<{ theme: Theme }>`
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: ${getColor('surfaceWhite')};
  box-shadow: 0 2px 4px 2px ${getColor('surfaceBlack')}19;
  width: 140px;
  padding: 16px;
  text-transform: uppercase;
  gap: 16px;

  .submenu-button {
    text-transform: uppercase;
  }

  @media (${breakpoint.lgMin}) {
    width: 180px;
  }

  li:last-child {
    border-top: 1px solid ${getColor('borderPrimary')};
    padding-top: 16px;
    margin-top: 16px;
  }

  &.crypto {
    left: 0;
  }
`;

export const SubMenuItem = styled.li<{ theme: Theme }>`
  button, a {
    font-family: ${font.graphikCond};
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;

    &:hover {
      color: ${getColor('textSecondary')};
    }

    @media (${breakpoint.lgMin}) {
      line-height: 24px;
    }
  }
`;
