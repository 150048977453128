import { useEffect, useRef } from 'react';
import { ScriptObserverItem } from 'interfaces/Utils';
import { LoggerTags } from 'utils/log/constants/loggerTags';
import logToBackend from 'utils/log/logToBackend';
import { AppErrorSeverity } from 'utils/log/constants/errorSeverity';

const scriptObserver = (scriptsList: ScriptObserverItem[]) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const observersRef = useRef<MutationObserver[] | null>(null);

  if (typeof window !== 'undefined' && observersRef.current === null) {
    const observerCallback = (mutationsList: MutationRecord[]) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList') {
          // Loop through added nodes
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLScriptElement) {
              const observeScript = scriptsList.find((script) => node.src.includes(script.inSrc));
              if (observeScript) {
                // eslint-disable-next-line no-param-reassign
                node.onerror = () => {
                  logToBackend(LoggerTags.SCRIPT_ERROR, 'onerror', {
                    name: observeScript.name,
                    severity: observeScript.errorSeverity ?? AppErrorSeverity.NORMAL,
                    src: node.src,
                    url: window.location.href,
                  });
                };
              }
            }
          });
        }
      });
    };

    // Create a MutationObserver instance
    const headObserver = new MutationObserver(observerCallback);
    const bodyObserver = new MutationObserver(observerCallback);

    headObserver.observe(document.head, {
      childList: true,
      subtree: false,
    });
    bodyObserver.observe(document.body, {
      childList: true,
      subtree: false,
    });

    observersRef.current = [headObserver, bodyObserver];
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(
    () => () => {
      observersRef.current?.forEach((observer) => observer.disconnect());
      observersRef.current = null;
    },
    [],
  );

  return null;
};

export default scriptObserver;
